import { NgModule } from '@angular/core';
import { Routes, RouterModule,

  PreloadAllModules} from '@angular/router';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
  { path: 'home', loadChildren: () => import('./homeui/homeui.module').then(m => m.HomeuiModule) },

  {path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)},
  {path: 'finance', loadChildren: () => import('./finance/finance.module').then(m => m.FinanceModule)},
  {path: 'network', loadChildren: () => import('./networks/networks.module').then(m => m.NetworksModule)},
  {path: 'client', loadChildren: () => import('./client/client.module').then(m => m.ClientModule)},
  {path: 'bp', loadChildren: () => import('./bp/bp.module').then(m => m.BpModule)},
  {path: 'ap', loadChildren: () => import('./ap/ap.module').then(m => m.ApModule)},
  {path: 'reporting', loadChildren: () => import('./reporting/reporting.module').then(m => m.ReportingModule)},

  { path: 'crm', loadChildren: () => import('./crmui/crmui.module').then(m => m.CrmuiModule) },
  { path: 'biometric', loadChildren: () => import('./netpapbiometric/netpapbiometric.module').then(m => m.NetpapbiometricModule) },
  //  {path: '', redirectTo: '/home', pathMatch: 'full'},

  {path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule { }