import { Component, OnInit, ViewChild, ElementRef, Inject, InjectionToken } from '@angular/core';
import { ListDatasource } from '../services/list-datasource';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { AppConfig } from 'src/app/auth/app-config';
import { Router, ActivatedRoute } from '@angular/router';
import { fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import {ListQueryObj} from '../models/list-query-obj';
import { async } from '@angular/core/testing';
export const DETAIL_ROUTE_SECTION: InjectionToken<string> = new InjectionToken<string>('DETAIL_ROUTE_SECTION');
import * as moment from 'moment';
import { ListEventSourceService } from '../services/list-event-source.service';
import { StreamingListDataSource } from '../services/streaming-list-data-source';
@Component({
  selector: 'app-base-list',
  template: `
    <p>
      base-list works!
    </p>
  `,
  styleUrls: ['./base-list.component.scss']
})
export class BaseStreamingListComponent implements OnInit {
  @ViewChild(MatPaginator,{static: true}) paginator: MatPaginator;
  @ViewChild(MatSort,{static: true}) sort: MatSort;
  @ViewChild('input',{static: true}) input: ElementRef;
  reportData:any;

  constructor(public dataSource: StreamingListDataSource<any>, public config: AppConfig
    ,public snackBar: MatSnackBar, public router: Router,
  public route: ActivatedRoute, @Inject(DETAIL_ROUTE_SECTION) public detail_route_section:string,
    public queryObj: ListQueryObj = new ListQueryObj()) {
    }
  getFrm(d){
    if(!d){
      return "1992-01-25"
    }
    return d

  }
  getTo(d){
    if(!d){
      let date=new Date()
      date.setDate(date.getDate() + 1)
        console.log(date,"=====================================")
      return moment(date).format("YYYY-MM-DD")
    }
    return d
  }
  ngOnInit() {
    this.route.params.subscribe(params => {
    let a={}
    console.log("what are my params",params)
    console.log("===============================================================")
    //console.log(moment(formData['frm']).format("YYYY-MM-DD")+ "T00:00:00.000Z");
     a['frm']=moment(this.getFrm(params['frm'])).format("YYYY-MM-DD");
     a['to']=moment(this.getTo(params['to'])).format("YYYY-MM-DD");
     a['station']=params['station']
     if (params['filter']){
      this.queryObj.filter = params['filter']
      this.input.nativeElement.value=params['filter'];
     }
     console.log("id now",params)


    this.dataSource.fetchAll(0, this.config.pageSize,this.queryObj.sortByColumn, this.queryObj.sortAscDesc, this.queryObj.filter,a,params['id']);

    this.prepareReportData();

    })


    }
  prepareReportData(){
    console.log("thabiti")
    this.dataSource.resultData$.subscribe(data=>{

      this.reportData = data
    });
  }
  ngAfterViewInit() {
    if (this.input && this.input.nativeElement) {
      fromEvent(this.input.nativeElement, 'keyup')
        .pipe(
          debounceTime(800),
          distinctUntilChanged(),
          tap(() => {
            this.paginator.pageIndex = 0;
            this.loadItemsPage();
          })
        )
        .subscribe();
  
      this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
  
      merge(this.sort.sortChange, this.paginator.page)
        .pipe(
          tap(() => this.loadItemsPage())
        )
        .subscribe();
    }
  }
  
  loadItemsPage() {
    this.route.params.subscribe(params => {
      let a={}
      a['frm']=moment(this.getFrm(params['frm'])).format("YYYY-MM-DD");
      a['to']=moment(this.getTo(params['to'])).format("YYYY-MM-DD");
      a['station']=params['station']

      this.dataSource.fetchAll(this.paginator.pageIndex, this.paginator.pageSize, this.sort.active,
        this.sort.direction, this.input.nativeElement.value,a);
        this.prepareReportData();
    })

  }

  delete(id) {
    this.dataSource.delete(id).subscribe(data => {
      this.ngOnInit();
    });
  }

  
  refresh(){
    this.ngOnInit();
  }
  add() {
    this.router.navigate([this.detail_route_section],{relativeTo: this.route});
  }
  edit(id) {
    //let snackBarRef = this.snackBar.open(`Editing Item Id: #${id}`);
    this.router.navigate([this.detail_route_section, id],{relativeTo: this.route});

  }

 

 

  cleanUpDate(formData: any) {
    Object.keys(formData).forEach(key => {
        if (Object.prototype.toString.call(formData[key]) === '[object Date]') {
            // console.log(`Prop: ${key}, Value: ${formData[key]}, Type: ${typeof (formData[key])}`)

            //  alert(moment(formData[key]).format("YYYY-MM-DD"))
            formData[key] = moment(formData[key]).format("YYYY-MM-DD") + "T00:00:00.000Z";
            //formData[key] = moment(formData[key]).format("YYYY-MM-DDTHH:mm:ss");
        }
    });
}
  // cancel(url) {
  //   this.router.navigate([url], { relativeTo: this.route });
  // }

}
