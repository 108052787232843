import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe  implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items || !searchText) {
      return items;
    }

    searchText = searchText.toLowerCase();

    return items.filter(item => {
      // Customize the filtering logic based on your data structure
      return (
        item.time.toLowerCase().includes(searchText) ||
        item.topics.toLowerCase().includes(searchText) ||
        item.message.toLowerCase().includes(searchText)
      );
    });
  }
}