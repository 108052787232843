import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardContainerComponent } from './dashboard-container/dashboard-container.component';
import { DashTestComponent } from './dash-test/dash-test.component';
import { AuthModule } from '../auth/auth.module';
//import { NavigationModule } from '../navigation/navigation.module';

@NgModule({
  declarations: [DashboardContainerComponent, DashTestComponent],
  imports: [
    CommonModule,
    //NavigationModule,
    DashboardRoutingModule,
    AuthModule
  ]
})
export class DashboardModule { }
