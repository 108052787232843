import { ElementRef, HostListener, Input } from '@angular/core';
import { Directive } from '@angular/core';
import { PdfdownloadService } from '../services/pdfdownload.service';

@Directive({
  selector: '[appPdfdownload]'
})
export class PdfdownloadDirective {
  @Input() fileName: string;
  @Input() selector:string;
  constructor(private dservice:PdfdownloadService) {

   }
   @HostListener('click',['$event']) onClick($event){
     this.dservice.generatePdf(this.fileName,this.selector);
   }


}

