import { Injectable } from '@angular/core';
import { ListDataService } from 'src/app/common/services/list-data-service';
import { NetpapInvoice } from '../models/netpap_invoice';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from 'src/app/auth/app-config';

@Injectable({
  providedIn: 'root'
})
export class NetpapInvoiceListService extends ListDataService<NetpapInvoice> {

  constructor(public http: HttpClient, public config: AppConfig) {
    super(http, config, 'invoicing/netpapinvoices', 'invoicing/netpapinvoices/raise');
  }
  send_invoice(){
    return this.http.get(`${this.config.apiUrl}/management/netpapreminder/`)
  }
  defaulted_invoices(company:string){
    return this.http.get(`${this.config.apiUrl}/invoicing/companyunpaidinvoices/${company}/`)

  }
  
}
