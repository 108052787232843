import { Component, OnInit,Inject  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


import { Plan } from 'src/app/networks/models/plan';
import { PlanService } from 'src/app/networks/services/plan.service';
import { BandwidthToolComponent } from 'src/app/common/components/bandwidth-tool/bandwidth-tool.component';
import { Rad } from 'src/app/admin/model/user-list';
import { UserService } from 'src/app/admin/services/user.service';
import { CommonService } from 'src/app/admin/services/common.service';
import { PeriodCalculatorComponent } from '../period-calculator/period-calculator.component';
import { DataCalculatorComponent } from '../data-calculator/data-calculator.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Radpostauth } from 'src/app/networks/models/station';
import { ActiveSecret, ActiveUser, ActiveUserArp, ActiveUserBinding, ActiveUserFirewall, Radacct, Userqueue } from '../models/transaction';
import { StationReportService } from '../services/station-report.service';

@Component({
  selector: 'app-network-attribute-detail',
  templateUrl: './network-attribute-detail.component.html',
  styleUrls: ['./network-attribute-detail.component.scss']
})
export class NetworkAttributeDetailComponent implements OnInit {
  radpost:Radpostauth[]=[];
  radacct:Radacct[] = [];
  active_user:ActiveUser[]=[];
  active_secret:ActiveSecret[]=[];
  active_user_queue:Userqueue[]=[]
  active_user_firewall:ActiveUserFirewall[]=[]
  active_user_binding:ActiveUserBinding[]=[]
  active_user_arp:ActiveUserArp[]=[]
  error_state:string ="";
  _timeout: any = null;
  username: string;
  typ:string;
  id:string;
  router_details:any;
  perms:any;
  spinner:boolean=true;
  consumption:number=0;
  uptime:number=0;


  rad: Rad[];
  plans: Plan[];
  expiration: Date;
  imageUrldialog='assets/img/invoice.jpeg';

  constructor(private userService: UserService,
            public dialogRef: MatDialogRef<NetworkAttributeDetailComponent>,
             @Inject(MAT_DIALOG_DATA) public data: any,
              private planService: PlanService,
              private dialog:MatDialog,
              private commonService: CommonService,
              private stationService:StationReportService,
              private router: Router, private snackBar: MatSnackBar,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.username = this.data['username'];
    this.typ= this.data['typ'];
    this.id = this.data['id'];
    this.userService.getNetworkAttrByUsername(this.username).subscribe(data => {
      this.rad = data['results'];
      this.perms = data['perms']
      const k = Object.values(this.rad);

      for (const x in k)  {
        if (k.hasOwnProperty(x)) {
          if (k[x]['attribute'] === 'Expiration') {
            this.expiration = new Date(k[x]['value']);
          }
        }

      }

      //   if (this.rad.hasOwnProperty(x)) {
      //     console.log(x,this.rad);
      //   }
      //   // if (x['attribute'] === 'Expiration') {
      //   //     console.log(x['value']);
      //   // }

      // }
    });

    console.log(new Date());

    this.planService.getAll(0, 1000).subscribe(data => {
      this.plans = data['results'];
    });
  }



  updateAttr(id, req) {
    if(!this.id){
      this.snackBar.open("Please navigate to  a fixed broadband or vouchers/topups to make changes")
      return false;
    }
    console.log(req)
    // if(req['value'] === ''){
    //   return false;

    // }

    // if(req['attr'] === "rate_limit"){ //where there is bandwidth allow shaping;
    //   let bw = req['value']
    //   if (!bw || !bw.includes("/")){
    //     this.snackBar.open("Please set the initial speed first e.g 5M/5M");
    //     return false;
    //   }




    // }


     if(req['attr'] === "expiry"){
      let formData=req
      this.commonService.cleanUpDate(req);
      req=formData;
       }

    let data={"typ":this.typ,"username":this.username,"id":this.id,...req}
    let t = this;
    clearTimeout(this._timeout);
    this._timeout = setTimeout(function () {

      t.userService.setAttr(id,data).subscribe(d => {
        t.snackBar.open(d['message']);
        t.ngOnInit();
      });

    }, 1500);


  }

  changePlan(val) {
    this.userService.changePlan(this.username, { 'plan': val }).subscribe(data => {
      this.snackBar.open(data['message']);
      this.snackBar.open("Reload page to see effects");
    });
  }


  network_details(){
    this.userService.get_router_data(this.username,this.typ).subscribe(data=>{
        this.router_details=data['router'][0]
        this.router_details['caller_id']=this.router_details['caller-id'];
        this.router_details['limit_bytes_in']=this.router_details['limit-bytes-in'];
        this.router_details['limit_bytes_out']=this.router_details['limit-bytes-out'];

    })
  }

getBandwidthTool(id,req){

  const dialogRef  = this.dialog.open(BandwidthToolComponent,{
    width:'480px',
    data:req['value']

  });


  dialogRef.afterClosed().subscribe(result=>{
    if(result){
      req['value']=result;
      let data={"typ":this.typ,"username":this.username,"id":this.id,...req}
      this.userService.setAttr(id,data).subscribe(d => {
        this.snackBar.open(d['message']);
        this.ngOnInit();
      });

    }
  })

}
dataCalculator(id,req){
  const df  = this.dialog.open(DataCalculatorComponent,{
    width:'480px',
    data:req['value']
  });
  df.afterClosed().subscribe(result=>{
    if(result){
      req['value']=result;
      let data={"typ":this.typ,"username":this.username,"id":this.id,...req}
      this.userService.setAttr(id,data).subscribe(d => {
        this.snackBar.open(d['message']);
        this.ngOnInit();

      });

    }
  })
}
periodCalculator(id,req){
  const df  = this.dialog.open(PeriodCalculatorComponent,{
    width:'480px',
    data:req['value']
  });

  df.afterClosed().subscribe(result=>{
    if(result){
      req['value']=result;
      let data={"typ":this.typ,"username":this.username,"id":this.id,...req}
      this.userService.setAttr(id,data).subscribe(d => {
        this.snackBar.open(d['message']);
        this.ngOnInit();

      });

    }
  })

}

getStatus(e){

  //let data={"active_user":[{"id":"*80035D4E","name":"Margaret wairimu","service":"pppoe","caller-id":"E0:37:68:82:12:93","address":"172.16.1.170","uptime":"1h6m50s","encoding":"","session-id":"0x81D35D4E","limit-bytes-in":"0","limit-bytes-out":"0","radius":"false"}],"active_secret":[{"id":"*20E","name":"Margaret wairimu","service":"pppoe","caller-id":"","password":"Margaret wairimu","profile":"6MBPS","routes":"","ipv6-routes":"","limit-bytes-in":"0","limit-bytes-out":"0","last-logged-out":"2024-04-05 12:22:00","last-caller-id":"E0:37:68:82:12:93","last-disconnect-reason":"hung-up","disabled":"false"}],"postauth":[],"radacct":[]}

  console.log(e,e.tab.textLabel)
  if(e.tab.textLabel =="Status"){
    this.spinner=true;
   this.userService.getStatusOnUsername(this.username,this.typ).subscribe(data=>{
    this.spinner=false
    this.consumption=data['consumption']
    this.uptime=data['uptime']
    this.radpost = data['postauth'];
    this.radacct = data['radacct'];
    this.active_user = data['active_user'];
    this.active_secret= data['active_secret'];
    this.active_user_queue=data['active_user_queue']
    this.active_user_firewall=data['active_user_firewall'];
    this.active_user_binding = data['active_user_binding'];
    this.active_user_arp = data['active_user_arp'];
    if(data['error_state']){
      this.error_state = data['error_state']
    }

    
   })
  }
}
macBind(username:string,mac:string,user_type:string){
  this.snackBar.open("Submitting mac bind")
  this.userService.macBind(username,mac,user_type).subscribe(data=>{
    this.snackBar.open("Mac binding submitted")
  })
}
}
