import { Observable, throwError } from 'rxjs';
import { HttpParams, HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { AppConfig } from 'src/app/auth/app-config';
import * as moment from 'moment';
import * as io from 'socket.io-client';
import { Vendor } from 'src/app/networks/models/olt';

export class ListDataService<T> {
  public socket: any;
  constructor(public http: HttpClient, public config: AppConfig,
    public list_url: string, public detail_url: string) { 
      // console.log("sort by column: " + sortByColumn);
      // this.socket = io(this.config.chatUrl); 
    }

  getAll(pageNumber: number = 1, pageSize: number = 10, sortByColumn: string = "name", sortAscDesc: string = "asc", filter: string = '',filter_params:any={}): Observable<any> {
    //console.log("SortBy: "+ sortByColumn);
    let station =this.clean_request(filter_params['station'],"station")
    let frm =this.clean_request(filter_params['frm'],"frm")
    let to =this.clean_request(filter_params['to'],"to")
    console.log(frm,to,station)
    let params: HttpParams = new HttpParams()
      .set("limit", pageSize.toString())
      .set("offset", (pageNumber * pageSize).toString())
      .set("ordering", `${sortAscDesc == 'desc' ? '-' : ''}${sortByColumn}`)
      .set("station",station).set("frm",frm).set("to",to)
      ;

    if (filter && filter.length) {
      params = params.set("search", filter);
    }
    //console.log("inside list data service get all..");
    return this.http.get<any>(this.config.apiUrl + `/${this.list_url}/`, {
      params: params
    })
      .pipe(
        tap((items: any) => {
          //console.log(items);
        })
      );
  }
  streamOltDeviceInfo(pon_id: string, action: any, olt_id:any): Observable<any> {
    const url = `${this.config.apiUrl}/${this.detail_url}/device-config/`;
    const postData = { pon_id: pon_id, actions: action, id:olt_id };
    
  
    return this.http.post(url, postData).pipe(
      catchError(error => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  getVendors():Observable<Vendor>{
    return this.http.get<Vendor>(`${this.config.apiUrl}/devicemonitor/getvendors/`)
  }

  testConnection(id:string): Observable<any> {
    const url = `${this.config.apiUrl}/${this.detail_url}/test-connection/`;
    const postData = { action:'test-connection', id:id };
    
  
    return this.http.post(url, postData).pipe(
      catchError(error => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }
  getAllById(id: string, pageNumber: number = 1, pageSize: number = 10, sortByColumn: string = "name", sortAscDesc: string = "asc", filter: string = ''): Observable<any> {
    let params: HttpParams = new HttpParams()
      .set("limit", pageSize.toString())
      .set("offset", (pageNumber * pageSize).toString())
      .set("ordering", `${sortAscDesc == 'desc' ? '-' : ''}${sortByColumn}`)
      if (filter && filter.length) {
        params = params.set("search", filter);
      }
    return this.http.get<T>(`${this.config.apiUrl}/${this.list_url}/${id}/`,{     
       params: params
    }).pipe(tap((item:any)=>{
      
    }));
    
  }
  clean_request(filter,attribute){
    if(attribute ==="frm"){
      if(!filter){
        return moment("1992-02-25").format("YYYY-MM-DD")
      }
      return filter
    }
    if(attribute ==="to"){
      console.log("===========================++")
      console.log(filter)
      if(!filter){
        let date=new Date()
        date.setDate(date.getDate() + 1)
        console.log(date,"=====================================")
        return moment(date).format("YYYY-MM-DD")
      }
      return filter
    }
    if(attribute ==="station"){
      if(!filter){
         return ""
      }
      return filter
  }
  console.log("=====================================================")
  }
  getSingle(id: string): Observable<T> {
    return this.http.get<T>(`${this.config.apiUrl}/${this.detail_url}/${id}/`);
  }
 
  delete(id: string): Observable<T> {
    return this.http.delete<T>(`${this.config.apiUrl}/${this.detail_url}/${id}/`);
  }
  adopt(id: string): Observable<T> {
    return this.http.post<T>(`${this.config.apiUrl}/${this.detail_url}/adopt/`,{ device_id: id , action:"adopt"});
  }
  adopt_users(id: string): Observable<T> {
    return this.http.post<T>(`${this.config.apiUrl}/${this.detail_url}/adopt_users/`, { device_id: id , action:"adopt_users"});
  }
  download_users(id: string): Observable<T> {
    return this.http.post<T>(`${this.config.apiUrl}/${this.detail_url}/download_users/`, { device_id: id , action:"download_users"});
  }
  set_users(device_id: string, category_id: String,sub_category:string=""): Observable<T> {
    return this.http.post<T>(`${this.config.apiUrl}/${this.detail_url}/set_users/`, { device_id: device_id , action:"set_users", category_id: category_id,sub_category:sub_category});
  }
  set_user(user_id:string,device_id: string): Observable<T> {
    return this.http.post<T>(`${this.config.apiUrl}/${this.detail_url}/set_user/`, { device_id: device_id , action:"set_user", user_id:user_id});
  }

  reboot(id: string): Observable<T> {
    return this.http.post<T>(`${this.config.apiUrl}/${this.detail_url}/reboot/`, { device_id: id ,action:"reboot"});
  }
  shutdown(id: string): Observable<T> {
    return this.http.post<T>(`${this.config.apiUrl}/${this.detail_url}/shutdown/`, { device_id: id ,action:"shutdown"});
  }


  block(id: string): Observable<T> {
    return this.http.post<T>(`${this.config.apiUrl}/${this.detail_url}/block/`, { device_id: id ,action:"block"});
  }
 
  create(item: T): Observable<any> {
    //console.log("Details:", item);
    return this.http.post(`${this.config.apiUrl}/${this.detail_url}/`, item);
     // return this.http.post(`${this.config.apiUrl}/${this.list_url}/`, item);
  }

  update(id: string, item: T): Observable<any> {
    return this.http.put(`${this.config.apiUrl}/${this.detail_url}/${id}/`, item);
  }




}
