import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
@Injectable({
  providedIn: 'root'
})
export class PdfdownloadService {

  constructor() { }

    public generatePdf(filename:string,selector:string):void{
      var area = document.querySelector(selector) as HTMLElement | null;

      var HTML_Width = area.offsetWidth;
      var HTML_Height = area.offsetHeight;
      const pdfWidth = HTML_Width
      const pdfHeight = (pdfWidth * 1.5) + (15 * 2)

      const doc = new jsPDF('p', 'pt', [pdfWidth,pdfHeight]);

      const pageCount = Math.ceil(HTML_Height/pdfHeight) - 1;

      html2canvas(area,{allowTaint:true}).then(canvas=>{
        canvas.getContext('2d');


        const imgData = canvas.toDataURL('image/png',1.0);
        doc.addImage(imgData,'PNG',15,15,HTML_Width,HTML_Height);
        for(let i= 1;i <= pageCount;i++){
          doc.addPage([pdfWidth,pdfHeight])
          doc.addImage(imgData,'PNG',15, -(pdfHeight * i)+15, HTML_Width, HTML_Height)
        }
        // const imgProps = pdf.getImageProperties(imgData);
        // // const pdfWidth = pdf.internal.pageSize.getWidth();

        // // const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        // pdf.addImage(imgData,'PNG',0,0,pdfWidth,pdfHeight);
        doc.save(filename)
      })


    }
  }

