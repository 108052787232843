import { Injectable } from '@angular/core';
import { AppConfig } from './app-config';
import { throwError,Observable, BehaviorSubject } from 'rxjs';

import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { NavItem } from '../navigation/nav-item';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(private http: HttpClient, private config: AppConfig) { }

  getSystemMenus(): Observable<NavItem[]> {
    let menuString: string = localStorage.getItem("menus");
    if(menuString && menuString.length){
      let menus: NavItem[]  = JSON.parse(menuString);
      //console.log(menus);
      //console.log("menus from cache...");
      let obs$: BehaviorSubject<NavItem[]> = <BehaviorSubject<NavItem[]>>new BehaviorSubject(menus);
      return obs$.asObservable();
    }
    return this.http.get<NavItem[]>(this.config.apiUrl + '/admin/system-menus/')
      .pipe(
        tap((items: NavItem[])=>{
          localStorage.setItem('menus', JSON.stringify(items));
          //console.log("menus from server...");
          //console.log(items);
        })
       // catchError(this.handleError)
      );
  }
  private handleError(error: HttpErrorResponse | any) {
    console.error(`An error occurred:${ error}`);
    return throwError(error.message || error)
  }
}
