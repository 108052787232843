import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Company } from 'src/app/admin/model/company';
import { AuthService } from '../auth.service';
import { loginUser, User } from '../user';

@Component({
  selector: 'app-select-company',
  templateUrl: './select-company.component.html',
  styleUrls: ['./select-company.component.scss']
})
export class SelectCompanyComponent implements OnInit {
  origin:string;
  username:string;
  companies:Company;

  constructor(private route:ActivatedRoute,private router:Router,private mainService:AuthService,private snackBar:MatSnackBar) { }

  ngOnInit(): void {
    this.origin = this.route.snapshot.paramMap.get("origin");
    this.username = this.route.snapshot.paramMap.get("username");

    this.mainService.getUserCompany(this.username).subscribe(data => {
      this.companies = data
    })
  }

  recover(e){
    console.log(e)
    let company:string =  e?.source?.selectedOptions?.selected[0]?.value

    let dat:loginUser = {
      company:company,
      username:this.username,
      password:''

    }
    console.log(this.origin,"-----")
    if(this.origin=="login"){

      dat['password'] = localStorage.getItem("kyvl");
      this.mainService.login(dat).subscribe(
        data => {

        },
        error => {
          // this.alertService.error('Username or password is incorrect');
          console.log('Username or password is incorrect');
          // this.form.get('username').setValue(this.username);

          // alert('cannot login');
        });



    }else{

    this.mainService.recoverAccount(dat).subscribe(data=>{
      this.snackBar.open(data['detail']);
      if(data['detail'].includes("recovered") ){
        this.router.navigate(['/auth/login']);
      }

    })
  }
  }
}
