import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { AppConfig } from 'src/app/auth/app-config';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private http:HttpClient,private config:AppConfig) { }
  getReport(val:string[],endpoint:string,charts:string[]){
    let requests:Observable<any>[]=[];
    let params:HttpParams= new HttpParams().set("frm",val['frm']).set("to",val['to']).set("station",val['station'])
                                            .set("user_type",val['user_type']).set("period_type",val['period_type']);
                                    
    charts.forEach(elem =>{
      requests.push(this.http.get(`${this.config.apiUrl}${endpoint}`,{params: params.set("charts",elem)}));
    })
    
    return forkJoin(requests)
  }
}
