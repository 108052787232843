import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams, HttpClient } from '@angular/common/http';
import { AppConfig } from 'src/app/auth/app-config';


export interface InterfaceTraffic{
  x:Date;
  download:number;
  upload:number;
}

@Injectable({
  providedIn: 'root'
})
export class MainService {

  constructor(public http: HttpClient, public config: AppConfig) { }

  searchKey(key: string, endpoint: string) {
    return this.http.get(`${this.config.apiUrl}${endpoint}${key}/`);
  }
  getReport(username: string,typ:string,account:string=""):Observable<any>{
    let params: HttpParams = new HttpParams().set("username",username).set("typ",typ).set("account",account);

    return this.http.get<any>(`${this.config.apiUrl}/management/userreport/`,{params:params})
  }
  getTraffic(data):Observable<InterfaceTraffic>{
    if (!data['frm'].includes("T")){
      data['frm']=data['frm']+"T00:00:00.000Z"
      data['to']=data['to']+"T23:59:00.000Z"
    }

    let params:HttpParams =new HttpParams().set("frm",data['frm'] ).set("username",data['username']).set('to',data['to']).set('station_code',data['station_code']).set("breakdown_typ",data['breakdown_typ'])

    return this.http.get<InterfaceTraffic>(`${this.config.reportUrl}/api/customerreport/`,{params:params})
  }

}
