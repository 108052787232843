import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { BaseStreamingListComponent } from '../base-list/base-streaming-list';
import { OnuDeviceService } from 'src/app/networks/onu-device.service';
import { AppConfig } from 'src/app/auth/app-config';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/admin/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ONU } from 'src/app/networks/models/onu';
import { StreamingListDataSource } from '../services/streaming-list-data-source';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-device-config-attributes',
  templateUrl: './device-config-attributes.component.html',
  styleUrls: ['./device-config-attributes.component.scss']
})
export class DeviceConfigAttributesComponent extends BaseStreamingListComponent
  implements OnInit {
  router_details: any;


  imageUrldialog = "assets/img/invoice.jpeg";
  detailForm: FormGroup;
  info: any;

  actionType: string; // Define a property to store the action type (bulkuser or import)
  pon_id: string; // Define a property to store the action type (bulkuser or import)
  port: string; // Define a property to store the action type (bulkuser or import)
  id: string; // Define a property to store the action type (bulkuser or import)

  constructor(
    private mainService: OnuDeviceService,
    public config: AppConfig,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DeviceConfigAttributesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commonService: CommonService,
    public router: Router,
    private cdr: ChangeDetectorRef,// Inject ChangeDetectorRef
    public route: ActivatedRoute,
    private dialog: MatDialog,

    public snackBar: MatSnackBar,
    // private streamingDataSource: StreamingListDataSource<ONU> // Inject StreamingListDataSource

  ) {
    super(
      new StreamingListDataSource<ONU>(mainService),
      config,
      snackBar,
      router,
      route,
      "onudevices"
    );
    this.actionType = this.data.type; // Get the action type from the data passed to the dialog
    this.port = this.data.port ?? ''; // Get the action type from the data passed to the dialog
    this.pon_id = this.data.pon_id ?? ''; // Get the action type from the data passed to the dialog
    this.id = this.data.id ?? ''; // Get the action type from the data passed to the dialog

  }


  ngOnInit() {
    console.log(new Date());

    if (this.actionType === "logs") {

      // this.mainService.getAll(0, 1000).subscribe((data) => {
      //   this.info = data["results"];
      // });

      this.mainService.streamOltDeviceInfo('1', 'fan').subscribe(info => {
        this.info = info['results'];
        this.cdr.detectChanges(); // Manually trigger change detection

        console.log("info now", this.info.length);

      });
    }
    if (this.actionType === "reboot") {
      console.log("reboot information", this.actionType)
    }
    if (this.actionType === "restore") {
      console.log("restore information", this.actionType)
    }




  }

  getObjectKeys(obj: any): string[] {
    console.log(" objects information", Object.keys(obj));

    return Object.keys(obj);
  }



  save(e) {
    this.config.hide(e);
    e.preventDefault();

    if (this.actionType === "reboot") {
      this.mainService.onuActions(this.pon_id, this.port, ['reboot'], this.id).subscribe(info => {
        this.info = info['results'];
        this.cdr.detectChanges(); // Manually trigger change detection

        console.log("info now", this.info.length);

      });
    }
    if (this.actionType === "restore") {
      this.mainService.onuActions(this.pon_id, this.port, ['restore'], this.id).subscribe(info => {
        this.info = info['results'];
        this.cdr.detectChanges(); // Manually trigger change detection

        console.log("info now", this.info.length);

      });
    }
   
  }
  cancel() {
    this.dialogRef.close();
  }
}
