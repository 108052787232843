import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/admin/services/common.service';
import { StationListService } from 'src/app/networks/services/station.service';
import { SearchService } from './search.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {
  searchForm:FormGroup;
  stations=[];
  spinner=false;
  @Input() endpoint:string;
  @Input() charts:string[];
  @Output() emitResponse: EventEmitter<Observable<any>[]> = new EventEmitter();
  constructor(private fb:FormBuilder,private stationService:StationListService,private commonService:CommonService,private mainService:SearchService) { }

  ngOnInit() {
    this.searchForm = this.fb.group({
      frm:['',Validators.required],
      to:['',Validators.required],
      user_type:['',Validators.required],
      station:['ALL'],
      period_type:['d']
    });
    this.stationService.getMinimizedStation().subscribe(data => {
      this.stations = data['results'];
      
    });
  }

  analysis(){
    let data =this.searchForm.getRawValue();
    this.commonService.cleanUpDate(data);

    let d = this.mainService.getReport(data,this.endpoint,this.charts);
    d.subscribe(response=>{
      this.emitResponse.emit(response)
    })
  }


}
