import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mobile-splash',
  templateUrl: './mobile-splash.component.html',
  styleUrls: ['./mobile-splash.component.scss']
})
export class MobileSplashComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit() {
  }

  open(){
    this.router.navigate(['/client/mobile/login'])
  }
}
