import { Injectable } from '@angular/core';
import { ListDataService } from 'src/app/common/services/list-data-service';
import { BiometricCategory } from '../models/biometric';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from 'src/app/auth/app-config';

@Injectable({
  providedIn: 'root'
})
export class BiometricCategoryService extends ListDataService<BiometricCategory> {

  constructor(public http:HttpClient,public config:AppConfig) {
    super(http,config,"netpapbiometric/categories","netpapbiometric/category")
   }
}
