import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-username-consumption-report',
  templateUrl: './username-consumption-report.component.html',
  styleUrls: ['./username-consumption-report.component.scss']
})
export class UsernameConsumptionReportComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
