import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { AppConfig } from 'src/app/auth/app-config';

@Component({
  selector: 'app-prompt-question',
  templateUrl: './prompt-question.component.html',
  styleUrls: ['./prompt-question.component.scss']
})
export class PromptQuestionComponent implements OnInit {

  constructor(private config:AppConfig,public dialogRef: MatDialogRef<PromptQuestionComponent>) { }


  ngOnInit() {
  }

  select(e,status){
    this.config.hide(e);
    this.dialogRef.close(status);
  }
}
