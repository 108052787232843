import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Question } from 'src/app/admin/model/question';
import { SecurityService } from 'src/app/admin/services/security.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';

@Component({
  selector: 'app-add-security-questions',
  templateUrl: './add-security-questions.component.html',
  styleUrls: ['./add-security-questions.component.scss']
})
export class AddSecurityQuestionsComponent implements OnInit {
  questions: Question[];
  question_answer: any[] = [];
  detailForm: FormGroup;
  passwordForm: FormGroup;
  user: string;
  showCodeButton = true;
  hideQuestionnaire = false;
  otpRow = false;

  showTimer = false;
  public stopper;
  public state = new BehaviorSubject({
    minutes: 1,
    seconds: 60

  });

  constructor(private securityService: SecurityService, private router: Router,
    private authUser: AuthService, public location: Location,
    private route: ActivatedRoute,
    private fb: FormBuilder, private snackBar: MatSnackBar) { }


  ngOnInit() {
    const company = this.authUser.getUserDetails()['company'];
    this.user = this.authUser.getUserDetails()['user'];
    this.snackBar.open('Please add security questions to your account.');
    this.securityService.getAll(company, 0, 1000).subscribe(data => {
      this.questions = data['results'];
    });

    // get users  security question


    this.detailForm = this.fb.group({
    question: [''],
    answer: [''],
    otp: ['', Validators.required]
    });
    this.passwordForm = this.fb.group({
    new_password: ['', Validators.required],
    confirm_password: ['', Validators.required],
    old_password: ['', Validators.required],
    otp: ['', Validators.required]
    });

}

add() {
// check if that is added in this array
  if (this.detailForm.get('answer').value === '') { this.snackBar.open('Please specify an answer!'); return false; }
  if (this.question_answer.length >= 3 ) {
    this.snackBar.open('Dear customer,3 Questions are enough'); this.hideQuestionnaire = true; return false;
  }
  this.question_answer = this.question_answer.reverse();
  let unique = true;
  if (this.question_answer.length > 0 ) {
for (let i = 0; i < this.question_answer.length; i++) {

    if (this.question_answer[i]['question'] === this.detailForm.get('question').value) {
      console.log("vreaking");
      unique = false;
      continue;
    }
  }
  if (unique) {
    this.question_answer.push(this.detailForm.value);
    this.deleteObj(this.detailForm.value)
  }
  } else {
    this.question_answer.push(this.detailForm.value);
    this.deleteObj(this.detailForm.value)

  }
    if (this.question_answer.length >= 3) {
      this.hideQuestionnaire = true;
      this.otpRow = true;
    }

  this.detailForm.get('answer').setValue('');
}
deleteObj(val) {

  for (let i = 0; i < this.questions.length; i++) {
    if (val['question'] === this.questions[i]['name']) {

      this.questions.splice(i, 1);
    }
}
}

submit() {

  if (this.question_answer.length > 0 && this.detailForm.get('otp').value) {

    this.securityService.addUserQuestion({'questions': this.question_answer,
                                          'otp': this.detailForm.get('otp').value }).subscribe(data => {
        this.snackBar.open('Security question has been added');
        this.showCodeButton = true;
         this.router.navigate(['/dashboard/']);
      });
      this.showTimer = false;
    } else {
      this.snackBar.open('There are form errors.Please correct the issue');
    }
  }
  getOTP() {
    if (this.user) {
      this.initTimer();
      this.showCodeButton = false;
      this.securityService.getOTP(this.user).subscribe(data => {
        this.showTimer = true;
        this.startTimer();

        this.showCodeButton = false;
        this.snackBar.open('A passcode has been sent to your email');
      });
    } else {
      this.snackBar.open('Please get the answer first..');
    }
  }


  initTimer() {
    const update = this.state.value;
    update.seconds = 60;
    update.minutes = 1;
  }

  startTimer() { // count to zero

    const update = this.state.value;

    this.stopper = setInterval((k, v) => {

      if (update.seconds > 0) {
        update.seconds -= 1;


      }
      if (update.seconds === 0 && update.minutes >= 1) {
        update.seconds = 60;
        update.minutes -= 1;
      }
      if (update.seconds === 0 && update.minutes === 0) {
        update.minutes = 0;
        clearInterval(this.stopper);
        this.showCodeButton = true;

      }
      this.state.next(update);

    }, 1000);
  }

  cancel() {
    this.location.back();
  }

}


