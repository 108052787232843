import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {
  data:any;
  title = 'Error Dialog';
  imageUrldialog='assets/img/invoice.jpeg';

  constructor(@Inject(MAT_DIALOG_DATA) public dat: string[]) {
    console.log("Messages:==== ", dat);
    this.data=dat;
    console.log("planning")
 
  }
  ngOnInit() {
    console.log("this is de")
    console.log(this.data)
  }

}
