import { Injectable } from "@angular/core";

//import { AppConfig } from '../app-config';
import { EMPTY, Observable, throwError, timer } from "rxjs";
import {
  map,
  tap,
  catchError,
  retry,
  share,
  retryWhen,
  delayWhen,
  filter,
  switchMap,
} from "rxjs/operators";
import {
  HttpErrorResponse,
  HttpClient,
  HttpParams,
} from "@angular/common/http";
import * as moment from "moment";
import { AppConfig } from "./app-config";
import { loginUser, User } from "./user";

import { Router } from "@angular/router";
import jwt_decode from "jwt-decode";
import { Upgrade } from "../admin/model/upgrade";
import { Company } from "../admin/model/company";
import { UserDetails } from "./user.models";
import { WebSocketSubject, webSocket } from "rxjs/webSocket";
interface MyToken {
  name: string;
  exp: number;
  // whatever else is in the JWT.
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public socket$!: WebSocketSubject<any>;
  private readonly MAX_RECONNECT_ATTEMPTS = 20;
  private reconnectAttempts = 0;
  private readonly TR069_TOKEN_STORAGE_KEY = "tr069_access_token";
  private readonly TR069_REFRESH_TOKEN_STORAGE_KEY = "tr069_refresh_token";

  constructor(
    private http: HttpClient,
    private config: AppConfig,
    private router: Router
  ) {
    this.connect();
    // this.listenForCsvData();
  }

  login(user: loginUser) {
    //console.log('trying to  login..')
    //console.log(this.config.apiUrl + '-auth/token/obtain/')
    return this.http
      .post(this.config.apiUrl + "-auth/token/obtain/", user)
      .pipe(
        tap((response: Response) => {
          // login successful if there's a jwt token in the response
          //console.log('some response...');
          //console.log(response);
          let authResult = response as any;

          if (authResult.hasOwnProperty("detail")) {
            localStorage.setItem("kyvl", user.password);
            this.router.navigate([
              "/auth/selectcompany",
              user.username,
              "login",
            ]);
          }

          if (authResult && authResult.access) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            //localStorage.setItem('currentUser', JSON.stringify(user));
            this.setSession(authResult);

            const user_details = this.getUserDetails();
            const password_reset = user_details["password_reset"];
            const default_status = user_details["default_status"];
            console.log("NEW iDENTIES", user_details);
            if (password_reset === true) {
              this.router.navigate(["/auth/resetpassword/"]);
            } else {
              console.log(user_details["dashboard_redirection"]);
              if (user_details["dashboard_redirection"] === "Analytics") {
                console.log(default_status);
                if (default_status) {
                  // redirect to dashboard
                  this.router.navigate(["/auth/defaultpage"]);
                }

                this.router.navigate([
                  "/network/networksetup/networksanalytics",
                ]);
              } else if (
                user_details["dashboard_redirection"] === "Client Dashboard"
              ) {
                localStorage.getItem("dashboard_redirection");
                this.router.navigate(["/client/activities/dashboard"]);
              } else if (user_details["dashboard_redirection"] === "Agency") {
                this.router.navigate(["/network/agency/batches"]);
              } else if (
                user_details["dashboard_redirection"] === "biometric"
              ) {
                this.router.navigate(["/biometric/biometricsetup/analytics"]);
              } else if (
                user_details["dashboard_redirection"] === "biometricusers"
              ) {
                this.router.navigate(["/biometric/biometricsetup/users"]);
              } else {
                console.log(default_status);
                if (default_status) {
                  // redirect to dashboard
                  this.router.navigate(["/auth/defaultpage"]);
                } else {
                  if (
                    !user_details["dashboard_redirection"] ||
                    user_details["dashboard_redirection"] == null
                  ) {
                    this.router.navigate([
                      "/network/networksetup/networksanalytics",
                    ]);
                    return true;
                  }

                  console.log(
                    user_details["dashboard_redirection"],
                    "----------------------",
                    user_details["dashboard_redirection"] == null
                  );

                  let red = user_details["dashboard_redirection"].charAt(0);
                  if (red === "/") {
                    this.router.navigate([
                      user_details["dashboard_redirection"],
                    ]);
                  } else {
                    this.router.navigate([
                      "/network/networksetup/networksanalytics",
                    ]);
                  }
                }
              }
            }
          }
        }),
        catchError(this.handleError)
      );
  }
  upgradeUser(upgrade: Upgrade) {
    return this.http.post(
      this.config.apiUrl + "/v1/client/tsc/register/",
      upgrade
    );
  }
  getUsername() {
    let details = this.getUserDetails();
    if (details["first_name"]) {
      return details["first_name"];
    }
    if (details["last_name"]) {
      return details["last_name"];
    }
    if (details["username"]) {
      return details["username"];
    }
  }
  registerUser(user: User) {
    return this.http.post(this.config.apiUrl + "/v1/client/register/", user);
  }
  getUserAccount(user: string) {
    return this.http.get(
      this.config.apiUrl + "/v1/client/register/" + user + "/"
    );
  }
  getCompany(security_code: string): Observable<Company> {
    return this.http.get<Company>(
      this.config.apiUrl + "/admin/companydetail/" + security_code + "/"
    );
  }

  getCompanies(): Observable<Company> {
    return this.http.get<Company>(this.config.apiUrl + "/allcompanies/");
  }

  recoverAccount(data) {
    data['company']=localStorage.getItem('company');
    return this.http.post(
      this.config.apiUrl + "/v1/client/account/recover/",
      data
    );
  }
  resetAccount(account: string) {
    return this.http.post(
      this.config.apiUrl + "/v1/client/account/reset/",
      account
    );
  }
  setPassword(password: any) {
    return this.http.post(
      this.config.apiUrl + "/v1/client/account/setpassword/",
      password
    );
  }

  checkUsername(username) {
    username = String(username);
    if (username.startsWith("7") && username.length === 9) {
      return true;
    } else {
      return false;
    }
  }

  refreshToken() {
    // alert('refreshing...');
    if (localStorage.getItem("hidemenu") === "1") {
      return null;
    }
    let theToken = this.getToken();
    let theRefresh = this.getRefreshToken();
    console.log("refreshing token..");
    // console.log(`The Token: ${theToken}`);
    // console.log(`The Refresh Token: ${theRefresh}`);
    return this.http
      .post(this.config.apiUrl + "-auth/token/refresh/", {
        refresh: theRefresh,
      })
      .pipe(
        share(),
        map((response) => {
          let authResult = response as any;
          if (authResult && authResult.access) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            this.setSession(authResult);
            return authResult.access;
          }
          return null;
        }),
        catchError(this.handleRefreshError)
      );
  }

  

 
  private getTokenExpiration(token: string): Date {
    const decoded = jwt_decode(token) as { exp: number };
    return moment.unix(decoded.exp).toDate();
  }

  // private startTr069TokenRefreshTimer() {
  //   const token = this.getTr069Token();
  //   if (!token) return;

  //   const tokenExp = this.getTokenExpiration(token);
  //   const now = moment();
  //   const refreshTime = moment(tokenExp).subtract(5, "minutes"); // Refresh 5 minutes before token expiration

  //   const refreshInterval = refreshTime.diff(now);
  //   if (refreshInterval > 0) {
  //     setTimeout(() => {
  //       this.refreshTr069Token().subscribe(
  //         () => {},
  //         (error) => console.error("Error refreshing tr069 token automatically:", error)
  //       );
  //     }, refreshInterval);
  //   }
  // }

   refreshTr069Token(): Observable<string> {
    const token = this.getTr069Token();
    const refresh = this.getTr069RefreshToken();

    if (!token || !refresh) {
      return throwError("No tr069 token or refresh token available.");
    }

    return this.http.post(this.config.tr069_baseUrl + "/api2/token/refresh-token", { refresh_token: refresh }).pipe(
      map((response: any) => {
        const authResult = response as any;
        if (authResult && authResult.tr069_access_token) {
          localStorage.setItem(this.TR069_TOKEN_STORAGE_KEY, authResult.access_token);
          localStorage.setItem(this.TR069_REFRESH_TOKEN_STORAGE_KEY, authResult.refresh_token);
          return authResult.tr069_access_token;
        }
        throw new Error("Unable to refresh tr069 token.");
      }),
      catchError((error) => {
        this.logout(); // Log out user on tr069 token refresh failure
        return throwError(error);
      })
    );
  }

  logout() {
    // remove user from local storage to log user out
    //localStorage.removeItem('currentUser');
    localStorage.removeItem("token");
    localStorage.removeItem("hidemenu");
    localStorage.removeItem("first_name");
    localStorage.removeItem("last_name");
    localStorage.removeItem("username");
    localStorage.removeItem("paybill");
    localStorage.removeItem("kopokopo_number");
    localStorage.removeItem("tr069_access_token");
    localStorage.removeItem("tr069_refresh_token");

    localStorage.removeItem("menus");
    // localStorage.removeItem("company");Can be used to recover account
    localStorage.removeItem("dashboard_redirection");
    localStorage.removeItem("passoword_reset");
    localStorage.removeItem("user");
    localStorage.removeItem("company_name");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("documentation");
    localStorage.removeItem("changelog");
  }

  private handleError(error: HttpErrorResponse | any) {
    console.error("An error occurred.", error);
    // alert(error.message || error);
    return throwError(error.message || error);
  }
  private handleRefreshError(error: HttpErrorResponse | any) {
    console.error("A refresh error occurred.", error);
    // alert('refresh: '+error.message || error);
    return throwError(error.message || error);
  }

  private setSession(authResult) {
    localStorage.setItem("token", authResult.access);
    localStorage.setItem("refresh_token", authResult.refresh);

    const u = this.parseJwt(authResult.access);
    localStorage.setItem("user", u.user);
    localStorage.setItem("username", u.username);
 
    localStorage.setItem("first_name", u.first_name);
    localStorage.setItem("last_name", u.last_name);
    localStorage.setItem("company_name", u.company_name);
    localStorage.setItem("company", u.company_id);
    localStorage.setItem("password_reset", u.password_reset);
    localStorage.setItem("dashboard_redirection", u.dashboard_redirection);
    localStorage.setItem("documentation", u.documentation);
    localStorage.setItem("changelog", u.changelog);
    localStorage.setItem("paybill", u.paybill);
    localStorage.setItem("kopokopo_number", u.kopokopo_number);
    localStorage.setItem("currency", u.currency);
    localStorage.setItem("services", u.services);

    // Handle tr069 tokens separately
    if (u.tr069_access_token && u.tr069_refresh_token) {
      localStorage.setItem(this.TR069_TOKEN_STORAGE_KEY, u.tr069_access_token);
      localStorage.setItem(this.TR069_REFRESH_TOKEN_STORAGE_KEY, u.tr069_refresh_token);
      // this.startTr069TokenRefreshTimer();
    }
  }
  public isTokenExpired() {
    let refresh_token = this.getRefreshToken();
    if (refresh_token == null || refresh_token == undefined) {
      return true;
    }
    let refresh_decoded = jwt_decode<MyToken>(refresh_token);
    console.log(refresh_decoded.exp);
    return moment.unix(refresh_decoded.exp) < moment();
  }
  public isLoggedIn() {
    //let loggedIn = moment().isBefore(this.getExpiration());
    //console.log('loggedin check..');
    //return loggedIn;
    let token = this.getToken();
    //console.log('Token Check..');
    //console.log(token);
    //console.log('logged in value: '+ (token != null && token != undefined));
    return token != null && token != undefined && !this.isTokenExpired();
  }
  public parseJwt(token) {
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  getExpiration() {
    const expiration = localStorage.getItem("expires_at");
    console.log(`Expiration: ${expiration}`);
    console.log(`Now: ${moment()}`);

    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }

  getToken() {
    let theToken = localStorage.getItem("token");
    //console.log(`The Token:  ${theToken}`);
    return theToken;
  }
  getTr069Token(): string | null {
    return localStorage.getItem(this.TR069_TOKEN_STORAGE_KEY);
  }

  private getTr069RefreshToken(): string | null {
    return localStorage.getItem(this.TR069_REFRESH_TOKEN_STORAGE_KEY);
  }

  getRefreshToken() {
    let theToken = localStorage.getItem("refresh_token");
    return theToken;
  }
  getUserDetails(): UserDetails {
    const token = this.parseJwt(localStorage.getItem("token"));
    console.log(token);
    return {
      user: token["user"],
      currency: token["currency"],
      services: token["services"],
      company: token["company_id"],
      is_default: token["is_default"],
      is_default_company: token["is_default_company"],
      security_token: token["security_token"],
      company_type: token["company_type"],
      last_name: token["last_name"],
      first_name: token["first_name"],
      username: token["username"],
      dashboard_redirection: token["dashboard_redirection"],
      password_reset: token["password_reset"],
      should_secure: token["should_secure"],
      phone: token["phone"],
      default_status: token["default_status"],
      paybill: token["paybill"],
      kopokopo_number: token["kopokopo_number"],
      user_type: token["user_type"],
      tr069_access_token: token['tr069_access_token'],
      tr069_refresh_token: token['tr069_refresh_token'],
    };
  }
  getCompanyName() {
    return localStorage.getItem("company_name");
  }
  isMobile() {
    if (localStorage.getItem("dashboard_redirection") === "Client Dashboard") {
      return true;
    } else {
      return false;
    }
  }
  getSafClientUsers() {
    return this.http.get(this.config.apiUrl + "/admin/safclient/users/");
  }
  activate(data: any) {
    return this.http.post(this.config.apiUrl + "/client/activateuser/", data);
  }
  getAgreement(article_type, company) {
    let params: HttpParams = new HttpParams()
      .set("article_type", article_type)
      .set("company", company);
    return this.http.get(this.config.apiUrl + "/bridge/agreement/", {
      params: params,
    });
  }
  menuByPass() {
    localStorage.setItem("hidemenu", "1");
  }

  getUserCompany(username: string): Observable<Company> {
    let params: HttpParams = new HttpParams().set("username", username);
    return this.http.get<Company>(
      this.config.apiUrl + "/client/getusercompany/",
      { params: params }
    );
  }
  getUserCompanyRecover(username: string,company_name:string): Observable<Company> {
    
    return this.http.post<Company>(
      this.config.apiUrl + "/v1/client/account/recover",
      { username:username, "company_name":company_name}
    );
  }

  private connect(): void {
    const socketPath = this.config.wsocket + "/ws/notifications/";
    const company_id = localStorage.getItem("company");
    const username = localStorage.getItem("username");

    const socketUrl = this.constructSocketUrl(socketPath, company_id, username);

    this.socket$ = webSocket(socketUrl);

    // Subscribe to WebSocket messages
    this.socket$
      .pipe(
        catchError((error: any) => {
          // Log the WebSocket error
          console.error("WebSocket error:", error);
          // Attempt reconnection if the connection is lost
          return this.reconnect();
        })
      )
      .subscribe(
        (message) => this.handleMessage(message),
        () => {
          console.log("WebSocket connection closed");
          // Attempt reconnection if the connection is closed
          this.reconnect();
        }
      );

    // Send initial connection message
    this.send({ message: "CONN_SUCCESSFUL" });
}

private reconnect(): Observable<any> {
    // Increment the reconnect attempts counter
    this.reconnectAttempts++;

    // Check if the maximum number of reconnect attempts has been reached
    if (this.reconnectAttempts < this.MAX_RECONNECT_ATTEMPTS) {
      console.log(`Attempting to reconnect (attempt ${this.reconnectAttempts})...`);

      // Delay the reconnection attempt
      return timer(10000).pipe(
        switchMap(() => {
          // Reconnect by recreating the WebSocket instance
          this.connect();
          // Return an empty observable to suppress the error
          return EMPTY;
        })
      );
    } else {
      console.error("Exceeded maximum reconnection attempts.");
      // Throw an error to stop retrying
      throw new Error("Exceeded maximum reconnection attempts.");
    }
}

  private constructSocketUrl(
    socketPath: string,
    company_id: string | null,
    username: string | null
  ): string {
    // Append query parameters for authentication if company_id and username are available
    let url = socketPath;
    if (company_id && username) {
      const credentials = `${company_id}`;
      const encodedCredentials = btoa(credentials);
      url += `?authorization=${encodedCredentials}&Username=${username}&token=${this.getToken()}`;
      // url += `?authorization=${encodedCredentials}`;
    }
    return url;
  }

  private handleMessage(message: any): void {
    console.log("Received message:", message);
    const device_message=message.message

    if (message &&  device_message.channel === "DOWNLOAD_USERS_RESPONSE") {
      console.log("Received DOWNLOAD_USERS_RESPONSE message:", message);
      // this.handleCsvData( device_message.message);
    } else if (message && device_message.channel === "ATTENDANCE") {
      console.log("Received ATTENDANCE message:", message);
      // Handle attendance data here
    } else {
      // Handle other types of messages
    }
  }

  // private handleCsvData(data: any): void {
  //   console.log("data received in dcv processor ", data )
  //   const csvData = new TextEncoder().encode(data);


  //   console.log("Data received:", csvData);
  //   const blob = new Blob([csvData], { type: "text/csv" });
  //   const url = window.URL.createObjectURL(blob);
  //   const a = document.createElement("a");
  //   a.href = url;
  //   a.download = "biometric_users.csv";

  //   a.click();
  //   window.URL.revokeObjectURL(url);
  // }

  send(message: any): void {
    console.log("Sending message:", message);
    this.socket$.next(message);
  }

  isConnected(): boolean {
    return !!this.socket$ && !this.socket$.closed;
  }

  ngOnDestroy(): void {
    this.disconnect();
  }

  private disconnect(): void {
    if (this.socket$) {
      this.socket$.complete();
    }
  }

  onMessage(): Observable<any> {
    return this.socket$.asObservable().pipe(map((message) => message));
  }

  // listenForCsvData(): void {
  //   this.onMessage().subscribe((message) => {
  //     console.log("Received message listener:", message);
  //     const device_message=message.message
  //     console.log("receive messahe ", device_message)
  //     if (message && device_message.channel === "DOWNLOAD_USERS_RESPONSE") {
  //       console.log("Received DOWNLOAD_USERS_RESPONSE message:", message);
  //       this.handleCsvData(device_message.message);
  //     } else if (message) {
  //       console.log("Received message data extra:", message);
  //     }
  //   });
  // }
}
