import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardContainerComponent } from './dashboard-container/dashboard-container.component';
import { MainLayoutComponent } from '../auth/main-layout/main-layout.component';
import { AuthGuard } from '../auth/auth.guard';
import { DashTestComponent } from './dash-test/dash-test.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard', 
        //component: DashboardContainerComponent,
        children:[
          //{path:},
          {path:'main', component: DashboardContainerComponent},
          {path:'test', component: DashTestComponent}
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
