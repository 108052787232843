import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetpassword: FormGroup;

  constructor(private fb: FormBuilder, private route: ActivatedRoute,
    private router: Router, private authService: AuthService, private snackBar: MatSnackBar ) { }

  ngOnInit() {
    this.resetpassword = this.fb.group({
      new_password: ['', Validators.required],
      confirm_password: ['', Validators.required],
      old_password: ['', Validators.required],
    });
  }

  onSubmit() {
    const user = this.authService.getUserDetails()['user'];
    if (!this.resetpassword.invalid) {
      if (this.resetpassword.get('confirm_password').value !== this.resetpassword.get('new_password').value) {
        this.snackBar.open('Dear customer,New password confirmation has failed');
        return false;
      }
      this.authService.resetAccount({...this.resetpassword.value, ...{'user': user}}).subscribe(data => {
        this.snackBar.open('Password was reset successfully');
        this.router.navigate(['/auth/login']);
      });
    } else {
      this.snackBar.open('There was a form error.Please correct the fields');
    }

  }

}
