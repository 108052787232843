import { Component, OnInit, ViewChild, ElementRef, Inject, InjectionToken } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { AppConfig } from 'src/app/auth/app-config';
import { Router, ActivatedRoute } from '@angular/router';
import { fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { ListQueryObj } from '../models/list-query-obj';
import * as moment from 'moment';
import { Tr069ListDatasource as Tr069ListDatastore } from '../services/tr069-list-data-source.service';

export const DETAIL_ROUTE_SECTION: InjectionToken<string> = new InjectionToken<string>('DETAIL_ROUTE_SECTION');

@Component({
  selector: 'app-base-list-tr069',
  templateUrl: './base-list-tr069.component.html',
  styleUrls: ['./base-list-tr069.component.scss']
})
export class BaseListTr069Component implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('input', { static: true }) input: ElementRef;
  reportData: any;

  constructor(
    public dataSource: Tr069ListDatastore<any>,
    public config: AppConfig,
    public snackBar: MatSnackBar,
    public router: Router,
    public route: ActivatedRoute,
    @Inject(DETAIL_ROUTE_SECTION) public detail_route_section: string,
    public queryObj: ListQueryObj = new ListQueryObj()
  ) {}

  getFrm(d) {
    return d ? d : '1992-01-25';
  }

  getTo(d) {
    if (!d) {
      let date = new Date();
      date.setDate(date.getDate() + 1);
      return moment(date).format('YYYY-MM-DD');
    }
    return d;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
    let a={}
    console.log("what are my params",params)
    console.log("===============================================================")
    //console.log(moment(formData['frm']).format("YYYY-MM-DD")+ "T00:00:00.000Z");
     a['frm']=moment(this.getFrm(params['frm'])).format("YYYY-MM-DD");
     a['to']=moment(this.getTo(params['to'])).format("YYYY-MM-DD");
     a['station']=params['station']
     if (params['filter']){
      this.queryObj.filter = params['filter']
      this.input.nativeElement.value=params['filter'];
     }
     console.log("id now",params)

    this.dataSource.fetchAll(0, this.config.pageSize,this.queryObj.sortByColumn, this.queryObj.sortAscDesc, this.queryObj.filter);
    this.prepareReportData();

    })

    }

  prepareReportData() {
    this.dataSource.resultData$.subscribe(data => {
      this.reportData = data;
    });
  }

  ngAfterViewInit() {
    fromEvent(this.input?.nativeElement, 'keyup')
      .pipe(
        debounceTime(800),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadItemsPage();
        })
      )
      .subscribe();

    this.sort?.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort?.sortChange, this.paginator?.page)
      .pipe(
        tap(() => this.loadItemsPage())
      )
      .subscribe();
  }

  loadItemsPage() {
    this.route.params.subscribe(params => {
      let queryParams = {
        frm: moment(this.getFrm(params['frm'])).format('YYYY-MM-DD'),
        to: moment(this.getTo(params['to'])).format('YYYY-MM-DD'),
        station: params['station']
      };

      this.dataSource.fetchAll(
        this.paginator.pageIndex,
        this.paginator.pageSize,
        this.sort.active,
        this.sort.direction,
        this.input?.nativeElement.value,
      );
      this.prepareReportData();
    });
  }

  

  refresh(): void {
    this.ngOnInit();
  }

  addCPE(cpeData: any): void {
    this.dataSource.addCPE(cpeData).subscribe(() => {
      this.snackBar.open('CPE added successfully', 'Dismiss', {
        duration: 3000,
      });
      this.ngOnInit();
    }, error => {
      console.error('Failed to add CPE:', error);
      this.snackBar.open('Failed to add CPE', 'Dismiss', {
        duration: 3000,
      });
    });
  }

  updateCPE(id: string, cpeData: any): void {
    this.dataSource.updateCPE(id, cpeData).subscribe(() => {
      this.snackBar.open('CPE updated successfully', 'Dismiss', {
        duration: 3000,
      });
      this.ngOnInit();
    }, error => {
      console.error('Failed to update CPE:', error);
      this.snackBar.open('Failed to update CPE', 'Dismiss', {
        duration: 3000,
      });
    });
  }

  updateFirmware(id: string, firmware: string, sn:string): void {
    this.dataSource.updateFirmware(id, firmware).subscribe(() => {
      this.snackBar.open('Firmware updated successfully', 'Dismiss', {
        duration: 3000,
      });
      this.ngOnInit();
    }, error => {
      console.error('Failed to update firmware:', error);
      this.snackBar.open('Failed to update firmware', 'Dismiss', {
        duration: 3000,
      });
    });
  }

  updateSSID(id: string, ssid: string, session:string): void {
    this.dataSource.updateSSID(id, ssid, session).subscribe(() => {
      this.snackBar.open('SSID updated successfully', 'Dismiss', {
        duration: 3000,
      });
      this.ngOnInit();
    }, error => {
      console.error('Failed to update SSID:', error);
      this.snackBar.open('Failed to update SSID', 'Dismiss', {
        duration: 3000,
      });
    });
  }

  updatePassword(id: string, password: string, session:string): void {
    this.dataSource.updatePassword(id, password, session).subscribe(() => {
      this.snackBar.open('Password updated successfully', 'Dismiss', {
        duration: 3000,
      });
      this.ngOnInit();
    }, error => {
      console.error('Failed to update password:', error);
      this.snackBar.open('Failed to update password', 'Dismiss', {
        duration: 3000,
      });
    });
  }

  importCPE(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.dataSource.importCPE(file).subscribe(() => {
        this.snackBar.open('CPE import initiated', 'Dismiss', {
          duration: 3000,
        });
        this.ngOnInit();
      }, error => {
        console.error('Failed to import CPE:', error);
        this.snackBar.open('Failed to import CPE', 'Dismiss', {
          duration: 3000,
        });
      });
    }
  }

  exportCPE(id: string): void {
    this.dataSource.exportCPE(id).subscribe(() => {
      this.snackBar.open('CPE export initiated', 'Dismiss', {
        duration: 3000,
      });
      this.ngOnInit();
    }, error => {
      console.error('Failed to export CPE:', error);
      this.snackBar.open('Failed to export CPE', 'Dismiss', {
        duration: 3000,
      });
    });
  }

  uploadDocument(id: string, document: File): void {
    this.dataSource.uploadDocument(id, document).subscribe(() => {
      this.snackBar.open('Document uploaded successfully', 'Dismiss', {
        duration: 3000,
      });
      this.ngOnInit();
    }, error => {
      console.error('Failed to upload document:', error);
      this.snackBar.open('Failed to upload document', 'Dismiss', {
        duration: 3000,
      });
    });
  }

  cleanUpDate(formData: any) {
    Object.keys(formData).forEach(key => {
      if (Object.prototype.toString.call(formData[key]) === '[object Date]') {
        formData[key] = moment(formData[key]).format('YYYY-MM-DD') + 'T00:00:00.000Z';
      }
    });
  }


}
