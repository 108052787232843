import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StationReportService } from '../../services/station-report.service';
import { AppConfig } from 'src/app/auth/app-config';
import { throwError } from 'rxjs';
import Chart from 'chart.js';
import { HttpErrorResponse } from '@angular/common/http';
import { chartOptions, parseOptions } from 'src/app/networks/variables/charts';
declare const CanvasJS: any;


var mode = 'light';//(themeMode) ? themeMode : 'light';
var fonts = {
  base: 'Open Sans'
}

// Colors
var colors = {
  gray: {
    100: '#f6f9fc',
    200: '#e9ecef',
    300: '#dee2e6',
    400: '#ced4da',
    500: '#adb5bd',
    600: '#8898aa',
    700: '#525f7f',
    800: '#32325d',
    900: '#212529'
  },
  theme: {
    'default': '#172b4d',
    'primary': '#5e72e4',
    'secondary': '#f4f5f7',
    'info': '#11cdef',
    'success': '#2dce89',
    'danger': '#f5365c',
    'warning': '#fb6340'
  },
  black: '#12263F',
  white: '#FFFFFF',
  transparent: 'transparent',
};


const data = [
  [
    { 'id': '*2', 'name': '8 ether', 'default-name': 'combo1', 'type': 'ether', 'mtu': '1500', 'actual-mtu': '1500', 'l2mtu': '1580', 'max-l2mtu': '10222', 'mac-address': '48:A9:8A:6F:E3:AC', 'link-downs': '0', 'rx-byte': '0', 'tx-byte': '0', 'rx-packet': '0', 'tx-packet': '0', 'rx-drop': '0', 'tx-drop': '0', 'tx-queue-drop': '0', 'rx-error': '0', 'tx-error': '0', 'fp-rx-byte': '0', 'fp-tx-byte': '0', 'fp-rx-packet': '0', 'fp-tx-packet': '0', 'running': 'false', 'slave': 'true', 'disabled': 'false' },
    { 'id': '*3', 'name': 'ether1', 'default-name': 'ether1', 'type': 'ether', 'mtu': '1500', 'actual-mtu': '1500', 'l2mtu': '1580', 'max-l2mtu': '10222', 'mac-address': '48:A9:8A:6F:E3:AD', 'last-link-up-time': 'nov/26/2023 13:37:47', 'link-downs': '0', 'rx-byte': '2183614714055', 'tx-byte': '166749923457', 'rx-packet': '1701417338', 'tx-packet': '927715501', 'rx-drop': '0', 'tx-drop': '0', 'tx-queue-drop': '0', 'rx-error': '0', 'tx-error': '0', 'fp-rx-byte': '2183614714055', 'fp-tx-byte': '166749923457', 'fp-rx-packet': '1701417338', 'fp-tx-packet': '927715501', 'running': 'true', 'disabled': 'false' },
  ],
  // ... (other lists)
];

interface InterfaceObject {
  name: string;
  // other properties if needed
}
interface Resource{
  uptime:string;
  version:string
  'board-name':string;
  'cpu-load':string;
  'free-hdd-space':string;
  'free-memory':string;
  'total-memory':string;
  'total-hdd-space':string;
}
@Component({
  selector: 'app-station-report',
  templateUrl: './station-report.component.html',
  styleUrls: ['./station-report.component.scss']
})
export class StationReportComponent implements OnInit,AfterViewInit{
  response:any
  interface=[];
  isConnected: boolean = false; 
  interfaces=[];
  chart:any;
  graphdata:any=[];
  resources:any;
  rx=[];
  logs:any;
  tx=[];
  spinner:boolean=true;
  display_message:string=""
  private svg: any;
  private x_tym:Date;
  private margin = 50;
  private width = 750 - (this.margin * 2);
  private height = 400 - (this.margin * 2);
  searchTerm: string = '';
  imageUrldialog='assets/img/invoice.jpeg';

  constructor(private config:AppConfig,@Inject(MAT_DIALOG_DATA) public dta: any,private stationService:StationReportService) { 
  //   this.stationService.onMessage().subscribe(
  //     (message) => {
  //       console.log('Received message in component:', message);
  //       this.response= message; // Update your component property with the received data
  //     },
  //     (error) => console.error('WebSocket error in component:', error),
  //     () => console.log('WebSocket connection closed in component')
  //   );

   }
   public networkChart;
   public data: any;

   public updateOptions() {
    this.networkChart.data.datasets[0].data = this.data;
    this.networkChart.update();
  }

  


  ngOnInit(): void {

    this.stationService.connect(this.dta['station_code']);
    this.stationService.send({"channel":"interface_list","station":this.dta['station_code']})
    this.stationService.onMessage().subscribe(resp=>{
    this.spinner=false;
   
    if(resp['status_code']==400){
      this.display_message=resp['message']
      return throwError(resp['message']+" "+" Please resolve issue and launch pop up to view ");
      console.log("continues")
    }
    console.log(1)
    // if (Array.isArray(resp.message)) {
      console.log(2,resp['channel'])
     if(resp['channel']=="interface_list"){
          if(resp['message']){
            let r=resp['message']
            r.forEach((v,k)=>{
              this.interfaces.push({"name":v['name']})
            })
          }
    }else if(resp['channel']=="resources"){
      this.resources= resp['message'][0];
      console.log( this.resources,"----")
    }else if(resp['channel']=="log"){
      this.logs= resp['message']
      console.log( this.logs,"----")
    }else if(resp['channel']=="interface_report"){

    let m=resp['message'];
    let t=m['ftx']===null? 0:parseFloat(m['ftx'])/1024
    let r=m['frx']===null? 0:parseFloat(m['frx'])/1024
    this.x_tym=new Date(resp['current_tym']);
    this.tx.push({"y":t,"x":this.x_tym});
    this.rx.push({"y":r,"x":this.x_tym});
    console.log( this.tx,"----KAZI")
    // this.chart.options.data[0].dataPoints = this.rx;
    // this.chart.options.data[1].dataPoints = this.tx;

      // Update the chart data
    this.networkChart.data.datasets[0].data = this.rx;
    this.networkChart.data.datasets[1].data = this.tx;

    // Update the chart
    this.networkChart.update();
    
    // this.chart.render()
    }
    });

    
  
}




ngAfterViewInit(){
 

  parseOptions(Chart, chartOptions());

  
    

  let format="m:s"

  var chartNetworks = document.getElementById('chart-network');
  this.networkChart = new Chart(chartNetworks, {
  type: 'line',
  data: {
    labels: this.rx.map(point => new Date(point)),
    datasets: [
      {
        label: 'RX',
        data: this.rx,
        borderColor: '#FF0000',
        backgroundColor: 'rgba(255, 0, 0, 0.5)',
        // fill: false,
      },
      {
        label: 'TX',
        data: this.tx,
        borderColor: '#0000FF',
        backgroundColor: 'rgba(0, 0, 255, 0.5)',
        // fill: false,
      },
    ],
  },
  options: {
    scales: {
      yAxes: [
        {
          gridLines: {
            color: 'rgba(0, 0, 0, 0.1)',
          },
          ticks: {
            callback: function (value) {
              if (!(value % 10)) {
                return value + ' Kb/s';;
              }
            },
          },
        },
      ],
      xAxes: [
        {
          type: 'time',
          time: {
            parser: format,
            tooltipFormat: 'DD/MM/YYYY HH:mm:ss',
            unit: 'millisecond', // Set the unit to millisecond
            stepSize: 10, // Set the step size to 10 milliseconds
          },
          gridLines: {
            color: 'rgba(0, 0, 0, 0.1)',
          },
        },
      ],
    },
  },
  legend: {
    display: true,
    position:'bottom',
    labels: {
      fontColor: 'white', // You can set the color of the legend text here
    },
  },
});

console.log("tx data", this.tx)
console.log("rx data", this.rx)
let f={
  animationEnabled: true,
  theme: "light2",
  title:{
    text: "Network Traffic"
  },
  axisY: {
    suffix: " Kb/s"
  },
  axisX: {
  
    interval: 5,
    labelFormatter: function (e) {
    return CanvasJS.formatDate( e.value, format);
    },

  },
  data: [{
    type: "line",
    showInLegend: "true",
    name: "RX",
    color: "#FF0000",
     xValueType: "dateTime",
     xValueFormatString: format,
    // yValueFormatString: "#.## b/s",
    legendMarkerType: "square",
    dataPoints: this.rx
  },{
    type: "line",
    showInLegend: "true",
    name: "TX",
    color: "#0000FF",
    xValueType: "dateTime",
    // xValueFormatString: format,
    // yValueFormatString: "#.## b/s",
    legendMarkerType: "square",
    dataPoints: this.tx
  }]
}
// this.chart = new CanvasJS.Chart("bar",f);
this.networkChart.update()
// this.chart.render()
}
selectInterface(name:string){
  this.spinner=true;
  // this.tx=[{"y":0,"x":this.x_tym}];
  // this.rx=[{"y":0,"x":this.x_tym}];
  // if(this.tx.length>1){
  //   console.log("------->>",this.tx)
  //   this.tx=[];
  //   this.rx=[];
  //   this.chart.render()
  // }
   if(this.tx.length>1){
    this.tx=[];
    // this.rx=[];
    // this.chart.options.data[0].dataPoints = [];
    // this.chart.options.data[1].dataPoints = [];
    // Update the chart data
    this.networkChart.data.datasets[0].data = [];
    this.networkChart.data.datasets[1].data = [];

  // Update the chart
  this.networkChart.update();
    // this.chart.render();
   }
  // console.log(this.tx,this.tx,"Reset")
  // this.tx=[];
  // this.rx=[];
  // console.log(this.tx,"---->INTERFACE")
  // this.chart.render();
  // this.stationService.disconnect();
  // this.stationService.connect(this.dta['station_code']);
  this.stationService.send({"channel":"disconnect","station":this.dta['station_code'],"interface":name})

  this.stationService.send({"channel":"interface_report","station":this.dta['station_code'],"interface":name})

  
  // this.stationService.onMessage().subscribe(resp=>{
  //   this.spinner=false;
  //   if(resp['status_code']==400){
  //     this.display_message=resp['message']
  //     return throwError(resp['message']+" "+" Please resolve issue and launch pop up to view ");
  //   }

  //   let m=resp['message'];
  //   let t=m['ftx']===null? 0:parseFloat(m['ftx'])/1024
  //   let r=m['frx']===null? 0:parseFloat(m['frx'])/1024
  //   this.x_tym=new Date(resp['current_tym']);
  //   this.tx.push({"y":t,"x":this.x_tym});
  //   this.rx.push({"y":r,"x":this.x_tym});
    
  //   this.chart.options.data[0].dataPoints = this.rx;
  //   this.chart.options.data[1].dataPoints = this.tx;
    
  //   this.chart.render()

  // });


}

onTabClick(e:Event){
  this.spinner=true;
  if(e['index']==1){
    this.stationService.send({"channel":"resources","station":this.dta['station_code']})
  }
  else if(e['index']==2){
    this.stationService.send({"channel":"log","station":this.dta['station_code']})
  }
}
applyFilter(event:Event){
  const filterValue = (event.target as HTMLInputElement).value;
  return this.logs.filter(log => {
    const searchString = filterValue.toLowerCase();
    return (
      log.time.toLowerCase().includes(searchString) ||
      log.topics.toLowerCase().includes(searchString) ||
      log.message.toLowerCase().includes(searchString)
    );
  });
}

ngOnDestroy(): void {
  // Unsubscribe when the component is destroyed;
  console.log("DESTROY")
  this.stationService.send({"channel":"disconnect","station":this.dta['station_code']})
  this.stationService.disconnect();
}
}