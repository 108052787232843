import { Injectable } from '@angular/core';
import { BiometricBaseListDataService } from './biometric-base-list-data.service';
import { BiometricDevice } from '../models/biometric';
import { AppConfig } from 'src/app/auth/app-config';
import { HttpClient } from '@angular/common/http';
import { ListDataService } from 'src/app/common/services/list-data-service';

@Injectable({
  providedIn: 'root'
})
export class BiometricDeviceService extends ListDataService<BiometricDevice> {

  constructor(public http: HttpClient, public config: AppConfig) {
    super(http, config, 'netpapbiometric/devices', 'netpapbiometric/device');
  }

}
