import { Injectable } from '@angular/core';
import { ListDataService } from 'src/app/common/services/list-data-service';
import { Station } from '../models/station';
import { AppConfig } from 'src/app/auth/app-config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Interface } from 'src/app/admin/model/user-list';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StationListService extends ListDataService<Station> {

  constructor(public http: HttpClient, public config: AppConfig) {
    super(http, config, 'management/stations', 'management/station');
  }

  getStationStats(station: string,data: any){
    return this.http.put(`${this.config.apiUrl}/management/analytics/${station}/`,data);
  }
  getLandingPage(){
    return this.http.get(`${this.config.apiUrl}/management/landingpages/`);
  }
  runContentionManagement(val){
    return this.http.post(`${this.config.apiUrl}/management/stationruncontention/`,val);
  }

  getInterfaces(station_id:string):Observable<Interface>{
    return this.http.get<Interface>(`${this.config.apiUrl}/management/stationinterfaces/${station_id}/`);
  }

  getOnlineState(station_id:string){
    let params:HttpParams = new HttpParams().set("stationid",station_id)
    return this.http.get<Interface>(`${this.config.apiUrl}/management/stationonlinestatus/`,{params:params});
  }
  getMinimizedStation():Observable<Station>{
    return this.http.get<Station>(`${this.config.apiUrl}/management/stationlist/`)
  }
}
