// tr069-list-datasource.ts

import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Tr069Service } from './tr069.service';
import { Tr069Action } from 'src/app/networks/models/tr069Acion';

export class Tr069ListDatasource<T> implements DataSource<T> {
  private itemsSubject = new BehaviorSubject<T[]>([]);
  private summarySubject = new BehaviorSubject<T[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private itemsCountSubject = new BehaviorSubject<number>(0);

  public loading$ = this.loadingSubject.asObservable();
  public itemsCount$ = this.itemsCountSubject.asObservable();
  public resultData$ = this.itemsSubject.asObservable();
  public summary$ = this.summarySubject.asObservable();


  
  constructor(private tr069Service: Tr069Service) {}

  connect(collectionViewer: CollectionViewer): Observable<T[]> {
    return this.itemsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.loadingSubject.complete();
    this.itemsSubject.complete();
    this.summarySubject.complete();

  }

  fetchAll(pageNumber: number = 1, pageSize: number = 10, sortByColumn: string = "name", sortAscDesc: string = "asc", filter: string = ""): void {
    this.loadingSubject.next(true);
    
    this.tr069Service.getAll(pageNumber, pageSize, sortByColumn, sortAscDesc, filter)
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((data) => {
        this.itemsSubject.next(data.results); // Adjust based on your API response structure
        this.itemsCountSubject.next(data.count);
      });

      // this.tr069Service.getSummary()
      // .pipe(
      //   catchError(() => of(null))
      // )
      // .subscribe((summary) => {
      //   this.summarySubject.next(summary); // Update summary data
      // });
  }

  getSummaryData(): void {
    this.tr069Service.getSummary()
      .pipe(
        catchError(() => of(null))
      )
      .subscribe((summary) => {
        this.summarySubject.next(summary);
      });
  }



 

  // Example method for rebooting a device
  rebootDevice(id: number, sn:string): Observable<T> {
    return this.tr069Service.reboot(id, sn).pipe(
      catchError((error) => {
        console.error('Error:', error);
        return of(null); // Handle error gracefully
      })
    );
  }


  updateFirmware(id: string, firmware: string): Observable<T> {
    return this.tr069Service.updateFirmware(id, firmware).pipe(
      catchError((error) => {
        console.error('Error:', error);
        return of(null); // Handle error gracefully
      })
    );
  }

  updateSSID(id: string, ssid: string, session:string): Observable<any> {
    return this.tr069Service.updateSSID(id, ssid, session).pipe(
      catchError((error) => {
        console.error('Error:', error);
        return of(null); // Handle error gracefully
      })
    );
  }

  updatePassword(id: string, password: string, session:string): Observable<T> {
    return this.tr069Service.updatePassword(id, password, session).pipe(
      catchError((error) => {
        console.error('Error:', error);
        return of(null); // Handle error gracefully
      })
    );
  }

  uploadDocument(id: string, document: File): Observable<T> {
    return this.tr069Service.uploadDocument(id, document).pipe(
      catchError((error) => {
        console.error('Error:', error);
        return of(null); // Handle error gracefully
      })
    );
  }

  updateCPE(id: string, cpeData: any): Observable<T> {
    return this.tr069Service.updateCPE(id, cpeData).pipe(
      catchError((error) => {
        console.error('Error:', error);
        return of(null); // Handle error gracefully
      })
    );
  }

  addCPE(cpeData: any): Observable<T> {
    return this.tr069Service.addCPE(cpeData).pipe(
      catchError((error) => {
        console.error('Error:', error);
        return of(null); // Handle error gracefully
      })
    );
  }

  deleteCPE(id: string): Observable<T> {
    return this.tr069Service.deleteCPE(id).pipe(
      catchError((error) => {
        console.error('Error:', error);
        return of(null); // Handle error gracefully
      })
    );
  }

  exportCPE(id: string): Observable<T> {
    return this.tr069Service.exportCPE(id).pipe(
      catchError((error) => {
        console.error('Error:', error);
        return of(null); // Handle error gracefully
      })
    );
  }

  importCPE(file: File): Observable<any> {
    return this.tr069Service.importCPE(file).pipe(
      catchError((error) => {
        console.error('Error:', error);
        return of(null); // Handle error gracefully
      })
    );
  }


}
