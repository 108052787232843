import { Injectable } from "@angular/core";
import { BiometricUser } from "../models/biometric";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "src/app/auth/app-config";
import { BiometricBaseListDataService } from "./biometric-base-list-data.service";
import { ListDataService } from "src/app/common/services/list-data-service";
import { map, filter } from "rxjs/operators";
import { WebSocketSubject, webSocket } from "rxjs/webSocket";
import { Observable, throwError, timer } from "rxjs";
import { catchError, retryWhen, delayWhen, tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class BiometricUserService extends ListDataService<BiometricUser> {
  // public socket$!: WebSocketSubject<any>;
  // private readonly MAX_RECONNECT_ATTEMPTS = 5;
  // private reconnectAttempts = 0;

  constructor(public http: HttpClient, public config: AppConfig) {
    super(http, config, "netpapbiometric/users", "netpapbiometric/user");
    // this.connect();
    // this.listenForCsvData();
  }
  getUserAttendance(id:string){
    return this.http.get(`${this.config.apiUrl}/netpapbiometric/userattendance/${id}/`)
  }

  // private connect(): void {
  //   const socketPath = this.config.wsocket + "/ws/notifications/";
  //   const company_id = localStorage.getItem("company");
  //   const username = localStorage.getItem("username");

  //   const socketUrl = this.constructSocketUrl(socketPath, company_id, username);

  //   this.socket$ = webSocket(socketUrl);

  //   // Subscribe to WebSocket messages
  //   this.socket$
  //     .pipe(
  //       retryWhen((errors) =>
  //         errors.pipe(
  //           delayWhen(() => timer(5000)), // Retry after 1 second
  //           catchError((error: any) => {
  //             this.reconnectAttempts++;
  //             if (this.reconnectAttempts < this.MAX_RECONNECT_ATTEMPTS) {
  //               console.error(
  //                 `WebSocket error, reconnecting attempt ${this.reconnectAttempts}:`,
  //                 error
  //               );
  //               return throwError(error);
  //             } else {
  //               console.error(
  //                 `WebSocket error, exceeded maximum reconnection attempts.`,
  //                 error
  //               );
  //               throw error;
  //             }
  //           }),
  //           tap(() => {
  //             // Send message when reconnection is successful
  //             this.send({ message: "Reconnected successfully" });
  //           })
  //         )
  //       )
  //     )
  //     .subscribe(
  //       (message) => this.handleMessage(message),
  //       (error) => console.error("WebSocket error:", error),
  //       () => console.log("WebSocket connection closed")
  //     );
  //   this.send({ message: "CONN_SUCCESSFUL" });
  // }

  // private constructSocketUrl(
  //   socketPath: string,
  //   company_id: string | null,
  //   username: string | null
  // ): string {
  //   // Append query parameters for authentication if company_id and username are available
  //   let url = socketPath;
  //   if (company_id && username) {
  //     const credentials = `${company_id}`;
  //     const encodedCredentials = btoa(credentials);
  //     url += `?authorization=${encodedCredentials}&Username=${username}`;
  //     // url += `?authorization=${encodedCredentials}`;
  //   }
  //   return url;
  // }

  // private handleMessage(message: any): void {
  //   console.log("Received message:", message);

  //   if (message && message.channel === "DOWNLOAD_USERS_RESPONSE") {
  //     console.log("Received DOWNLOAD_USERS_RESPONSE message:", message);
  //     this.handleCsvData(message.data);
  //   } else if (message && message.channel === "ATTENDANCE") {
  //     console.log("Received ATTENDANCE message:", message);
  //     // Handle attendance data here
  //   } else {
  //     // Handle other types of messages
  //   }
  // }

  // private handleCsvData(data: any): void {
  //   const csvData = new TextEncoder().encode(data);

  //   console.log("Data received:", csvData);
  //   const blob = new Blob([csvData], { type: "text/csv" });
  //   const url = window.URL.createObjectURL(blob);
  //   const a = document.createElement("a");
  //   a.href = url;
  //   a.download = "biometric_users.csv";

  //   a.click();
  //   window.URL.revokeObjectURL(url);
  // }

  // send(message: any): void {
  //   console.log("Sending message:", message);
  //   this.socket$.next(message);
  // }

  // isConnected(): boolean {
  //   return !!this.socket$ && !this.socket$.closed;
  // }

  // ngOnDestroy(): void {
  //   this.disconnect();
  // }

  // private disconnect(): void {
  //   if (this.socket$) {
  //     this.socket$.complete();
  //   }
  // }

  // onMessage(): Observable<any> {
  //   return this.socket$.asObservable().pipe(map((message) => message));
  // }

  // listenForCsvData(): void {
  //   this.onMessage().subscribe((message) => {
  //     console.log("Received message:", message);
  //     if (message && message.channel === "DOWNLOAD_USERS_RESPONSE") {
  //       console.log("Received DOWNLOAD_USERS_RESPONSE message:", message);
  //       this.handleCsvData(message.data);
  //     } else if (message) {
  //       console.log("Received message:", message);
  //     }
  //   });
  // }
}
