import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Transaction } from '../../models/transaction';
import { Observable } from 'rxjs';

import { startWith, map } from 'rxjs/operators';
import { ListDataService } from '../../services/list-data-service';
import { MainService } from '../../services/main.service';
@Component({
  selector: 'app-loan-transaction-id',
  templateUrl: './loan-transaction-id.component.html',
  styleUrls: ['./loan-transaction-id.component.scss'],
})
export class LoanTransactionIdComponent implements OnInit {

  options: Transaction[];
  filteredOptions: Observable<Transaction[]>;

  @Output() tx_option: EventEmitter<any> = new EventEmitter();


  @Input() group: FormGroup;
  @Input() tx: string;
  @Input() isDisabled: string;
  transaction = new FormControl();
  constructor(public mainService: MainService) {

    }
  displayFn(transaction: any): string {
    console.log(transaction,"display fb")
    console.log("-=-------------------------------")
    console.log(this.tx)
    return typeof transaction === 'object' ? transaction.transaction_id : transaction;
  }
  private _filter(name: string): Transaction[] {
    console.log(name);
    if (this.options) {

      if (name) {
        const filterValue = name.toLowerCase();
        return this.options.filter(option => option.transaction_id.toLowerCase().indexOf(filterValue) === 0);
      }

    } else {
      this.searchKey(name);
    }
  }

  ngOnInit() {
    this.filteredOptions = this.transaction.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.transaction),
        map(name => this._filter(name))
      );
    console.log(this.filteredOptions);
    this.transaction.setValue(this.tx);

  }

  searchKey(k: string) {
    if (k.length > 3) {
      this.mainService.searchKey(k, '/sacco/searchloan/').subscribe(data => {
        this.options = data['results'];

      });
    }
  }
  selectedOption(e) {
    console.log(e);
    this.tx_option.emit(e);
  }
}

