import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  VERSION,
  OnDestroy,
  ChangeDetectorRef,
} from "@angular/core";
import { NavItem } from "../../navigation/nav-item";
import { NavService } from "../../navigation/nav.service";
import { AuthService } from "../auth.service";
import { MenuService } from "../menu-service";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { MediaMatcher } from "@angular/cdk/layout";
import { NotificationSoc } from "../user.models";

@Component({
  selector: "app-main-layout",
  templateUrl: "./main-layout.component.html",
  styleUrls: ["./main-layout.component.scss"],
})
export class MainLayoutComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild("appDrawer", { static: false }) appDrawer: ElementRef;
  mobileQuery: MediaQueryList;
  dealsSub: Subscription;
  navItems: NavItem[];
  error: any;
  first_name: string;
  last_name: string;
  company_name = "NETPAP";
  hidemenu: string = "";
  imageUrlside = "assets/img/OIP.png";

  username: string;
  version = VERSION;
  notifications:NotificationSoc[] = []; // Variable to store received notifications

  constructor(
    private navService: NavService,
    private router: Router,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public authService: AuthService,
    private menuService: MenuService
  ) {
    this.mobileQuery = media.matchMedia("(max-width: 600px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.listenForNotifications();
  }

  private listenForNotifications(): void {
    this.authService.onMessage().subscribe((message) => {
      console.log("Received message listener:", message);
      const device_message = message['message'];
      console.log("receive message ", device_message);
      if (message && device_message.channel === "ATTENDANCE") {
        console.log("Received ATTENDANCE message:", device_message,device_message.length);
        if (device_message.length > 0) {
          // Handle attendance data here
          // For example, add the attendance data to the notifications array
          this.notifications.push(device_message);
        } else {
          try{
            message=device_message['message']
            console.log(device_message,"----",message)
            this.notifications.push({ channel: message.channel, message: message});
          }catch(err){
            this.notifications.push({ channel: message.channel, message: ""});
          }
        }
      } else if (
        message &&
        device_message.channel === "DOWNLOAD_USERS_RESPONSE"
      ) {
        console.log("message from device", device_message.message);
        this.notifications.push(device_message);
        console.log("Received DOWNLOAD_USERS_RESPONSE message:", message);
      } else {
        console.log("device message received here", device_message)
        this.notifications.push(device_message);
      }
    });
    console.log("notifications data length", this.notifications.length);
  }
 
  removeNotification(index: number): void {
    // Remove the notification at the specified index from the array
    this.notifications.splice(index, 1);
  }

  public handleCsvData(data: any): void {
    console.log("data received in dcv processor ", data);
    const csvData = new TextEncoder().encode(data);

    console.log("Data received:", csvData);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "biometric_users.csv";

    a.click();
    window.URL.revokeObjectURL(url);
  }

  ngOnInit() {
    console.log("1");
    this.hidemenu = localStorage.getItem("hidemenu");
    if (!this.hidemenu) {
      this.dealsSub = this.menuService.getSystemMenus().subscribe(
        (menus) => {
          this.navItems = menus;
          // console.log("Menus:");
          // console.log(menus);
        },
        // err => this.error = err
        (err) => {
          // console.log(`Deal error: ${err}`);
          this.error = err;
        }
      );

      const user = this.authService.getUserDetails();
      this.first_name = user["first_name"];
      this.last_name = user["last_name"];
      this.username = user["username"];
      const password_reset = user["password_reset"];
      const default_status = user["default_status"];
      this.company_name = this.authService.getCompanyName();
      if (!this.company_name) {
        this.company_name = "NETPAP";
      }

      // check if security questions are created

      // if not make a request and redirect to this add security question page
      if (this.authService.isMobile()) {
        this.router.navigate(["/client/activities/dashboard"]);
      }
    } else {
    }
  }

  ngAfterViewInit() {
    this.navService.appDrawer = this.appDrawer;
  }

  private _mobileQueryListener: () => void;

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  logoutNow() {
    console.log(this.authService.isMobile(), "is mobile");
    if (this.authService.isMobile()) {
      this.authService.logout();
      this.router.navigate(["/client/mobile/login"]);
    } else {
      this.authService.logout();
      this.router.navigate(["/auth/login"]);
    }
  }
}
