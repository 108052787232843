import { DataSource, CollectionViewer } from "@angular/cdk/collections";
import { BehaviorSubject, Observable, of } from "rxjs";
import { ListDataService } from "./list-data-service";
import { catchError, finalize, map } from "rxjs/operators";
import { ActivatedRoute } from '@angular/router';

export class ListDatasource<T> implements DataSource<T> {
  public itemsSubject = new BehaviorSubject<T[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private itemsCountSubject = new BehaviorSubject<number>(1);
  private permsSubject = new BehaviorSubject<any>({});
  private totalSubject = new BehaviorSubject<any>({});

  public loading$ = this.loadingSubject.asObservable();
  public itemsCount$ = this.itemsCountSubject.asObservable();
  public perms$ = this.permsSubject.asObservable();
  public totals$ = this.totalSubject.asObservable();
  public resultData$ = this.itemsSubject.asObservable();

  constructor(private service: ListDataService<T>) {}
  connect(collectionViewer: CollectionViewer): Observable<T[]> {
    return this.itemsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.loadingSubject.complete();
    this.itemsSubject.complete();
  }
  delete(id: string): Observable<T> {
    return this.service.delete(id);
  }
  adopt(id: string): Observable<T> {
    return this.service.adopt(id);
  }
  adopt_users(id: string): Observable<T> {
    return this.service.adopt_users(id);
  }
  download_users(id: string): Observable<T> {
    return this.service.download_users(id);
  }
  set_users(device_id: string, category_id: string,sub_category:string=''): Observable<T> {
    return this.service.set_users(device_id, category_id,sub_category);
  }

  set_user(user_id: string, device_id: string): Observable<T> {
    return this.service.set_user(user_id, device_id);
  }
  block(id: string): Observable<T> {
    return this.service.block(id);
  }
  reboot(id: string): Observable<T> {
    return this.service.reboot(id);
  }
  shutdown(id: string): Observable<T> {
    return this.service.shutdown(id);
  }

  streamDevice(
    pon_id: string,
    action: any,
    olt_id: any
  ): Observable<T> {
    this.loadingSubject.next(true);
    console.log("we are here calling stream device")
    return this.service.streamOltDeviceInfo(pon_id, action, olt_id).pipe(
      catchError(() => of([])),
      map((data) => {
        this.itemsSubject.next(data?.results);
        this.itemsCountSubject.next(data?.count);
        this.permsSubject.next(data?.perms);
        this.totalSubject.next(data?.totals);
        return data;
      }),
      finalize(() => this.loadingSubject.next(false))
    );
  }

  fetchAllById(
    id: string,
    pageNumber: number = 1,
    pageSize: number = 50,
    sortByColumn: string = "name",
    sortAscDesc: string = "asc",
    filter: string = "",
    params: any = {}
  ) {
    this.loadingSubject.next(true);


    this.service
      .getAllById(
        id,
        pageNumber,
        pageSize,
        sortByColumn,
        sortAscDesc,
        filter,
      )
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((data) => {
        this.itemsSubject.next(data.results);
        this.itemsCountSubject.next(data.count);
        this.permsSubject.next(data.perms);
        this.totalSubject.next(data.totals);
        //console.log(data.results);
      });
  }

  fetchAll(
    pageNumber: number = 1,
    pageSize: number = 50,
    sortByColumn: string = "name",
    sortAscDesc: string = "asc",
    filter: string = "",
    params: any = {},
    id?: string,
  ) {
    this.loadingSubject.next(true);
    // Check if 'id' is undefined and retrieve it from route parameters
   
    if (id) {
      // If id is provided, fetchAllById
      this.fetchAllById(
        id,
        pageNumber,
        pageSize,
        sortByColumn,
        sortAscDesc,
        filter,
        params
      );
    } else {
      this.service
        .getAll(pageNumber, pageSize, sortByColumn, sortAscDesc, filter, params)
        .pipe(
          catchError(() => of([])),
          finalize(() => this.loadingSubject.next(false))
        )
        .subscribe((data) => {
          console.log("all the data i want", data)
          this.itemsSubject.next(data.results);
          this.itemsCountSubject.next(data.count);
          this.permsSubject.next(data.perms);
          this.totalSubject.next(data.totals);
          //console.log(data.results);
        });
    }
  }
}
