// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


  export const environment = {
    production: false,
    firebase: {
      apiKey: "AIzaSyA9hG6mG2QWpNRXbAtO9gMNYVtM4HOBbgA",
      authDomain: "netchat-422b1.firebaseapp.com",
      databaseURL: "https://netchat-422b1.firebaseio.com",
      projectId: "netchat-422b1",
      storageBucket: "netchat-422b1.appspot.com",
      messagingSenderId: "797897387198",
      appId: "1:797897387198:web:e235d7f0a7de29c4e542ef"
    }
  };
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
