import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig } from '../../app-config';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.scss']
})
export class AgreementComponent implements OnInit {
  detailForm:FormGroup;
  items:any;
  form:any;
  company:string;
  constructor(private fb:FormBuilder,private config:AppConfig,
    private router:Router,private route: ActivatedRoute,
    private snackBar:MatSnackBar,private authService:AuthService) { }

  ngOnInit() {
    this.company = this.route.snapshot.paramMap.get("company")
    this.detailForm = this.fb.group({
        username:['',Validators.required],
        password:['',Validators.required],
        terms:['',Validators.required],
        privacy:['',Validators.required],
        agreement:['',Validators.required],
        company:{disabled:true}
    });
    
    
  }
  activate(e,company){
    this.config.hide(e);
    let data = this.detailForm.getRawValue();
    if(!data['terms']){
      this.snackBar.open("Please agree to the terms and conditions of the network")
      return true
    }
    if(!data['privacy']){
      this.snackBar.open("Please agree to our privacy policy")
      return true
    }
    if(!data['agreement']){
      this.snackBar.open("Please agree to our sales agreement")
      return true
    }
    if(this.company){
      data['company'] = this.company 
    }
    data['company'] = company

    this.authService.activate(data).subscribe(p=>{
      console.log(p)
      if(p['count']){
        this.items=p['results'];
        this.form =this.items;
        console.log(this.items)
        console.log(p['results'])
        return true;
      }
      this.snackBar.open(p['message']);
      this.router.navigate(['/auth/login'])
    },fail=>{
      this.config.show(e);
    })
  }
  viewform(){
    this.form = ""
  }
  agreement(article_type){
    
      this.router.navigate(['/client/mobile/agreement',{"company":this.company,"article_type":article_type}]);

  }
}
