import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { NavigationModule } from '../navigation/navigation.module';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from '../material.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { MainLayoutComponent } from './main-layout/main-layout.component';
import { LoginLayoutComponent } from './login-layout/login-layout.component';
import { AuthInterceptor } from './auth-interceptor';
import { AppConfig } from './app-config';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SecurityQuestionComponent } from './security-question/security-question.component';
import { AppHelperModule } from '../common/app-helper.module';
import { AddSecurityQuestionsComponent } from './add-security-questions/add-security-questions.component';
import { PropertyCodeDetailComponent } from './property-code-detail/property-code-detail.component';
import { RegisterDetailComponent } from './register-detail/register-detail.component';
import { DefaultPageComponent } from './default-page/default-page.component';
import { MobileLoginComponent } from './mobile/mobile-login/mobile-login.component';
import { MobileSplashComponent } from './mobile/mobile-splash/mobile-splash.component';
import { MobileResetPasswordComponent } from './mobile/mobile-reset-password/mobile-reset-password.component';
import { MobileResetDashboardComponent } from './mobile/mobile-reset-dashboard/mobile-reset-dashboard.component';
import { MobileCompanyListComponent } from './mobile/mobile-company-list/mobile-company-list.component';
import { SplashComponent } from './splash/splash.component';
import { OrderingComponent } from './ordering/ordering.component';
import { AgreementComponent } from './mobile/agreement/agreement.component';
import { TermsComponent } from './terms/terms.component';
import { SelectCompanyComponent } from './select-company/select-company.component';

@NgModule({
  declarations: [LoginComponent, MainLayoutComponent, LoginLayoutComponent, ErrorDialogComponent, RecoverPasswordComponent, ResetPasswordComponent, SecurityQuestionComponent, AddSecurityQuestionsComponent, PropertyCodeDetailComponent, RegisterDetailComponent, DefaultPageComponent,
     MobileLoginComponent, MobileSplashComponent, MobileResetPasswordComponent, MobileResetDashboardComponent,  MobileCompanyListComponent, SplashComponent, OrderingComponent, AgreementComponent, TermsComponent, SelectCompanyComponent,  
    ],
  imports: [
    CommonModule,
    HttpClientModule,
    NavigationModule,
    ReactiveFormsModule,
    //BrowserAnimationsModule,
    MaterialModule,
    AppHelperModule,
    AuthRoutingModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    AppConfig
  ],
exports: [AddSecurityQuestionsComponent]
})
export class AuthModule { }
