
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from './../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/common/components/user/user.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppConfig } from '../app-config';

@Component({
  selector: 'app-property-code-detail',
  templateUrl: './property-code-detail.component.html',
  styleUrls: ['./property-code-detail.component.scss']
})
export class PropertyCodeDetailComponent implements OnInit {
  form: FormGroup;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appConfig: AppConfig,
    private fb: FormBuilder,         // {3}
    private authService: AuthService, // {4},
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.form = this.fb.group({     // {5}
      security_code: ['', [Validators.required]],
      name: ['', Validators.required],
    });


  }
  onSubmit(){
    this.authService.getCompany(this.form.value).subscribe(data => {
      this.form.patchValue({...data});
    });
  }
}
