import { Directive, HostListener, OnInit } from '@angular/core';

@Directive({
  selector: '[appNoDblClick]'
})
export class NoDblClickDirective{

  constructor() { }

  @HostListener('click', ['$event'])
  clickEvent(event){
    event.preventDefault();
    event.stopPropagation();
    event.srcElement.setAttribute('disabled',true);
    setTimeout(() => {
      event.srcElement.removeAttribute('disabled');
    }, 5000);
  }
}


@Directive({
  selector: '[appDebounceClick]'
})
export class DebounceClickDirective implements OnInit {
  constructor() {}

  ngOnInit() {}

  @HostListener('click', ['$event'])
  clickEvent(event) {

    alert('clicking..');

    event.preventDefault();
    event.stopPropagation();
    console.log('Click from Host Element!');
  }
}
