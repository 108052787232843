import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AppConfig } from 'src/app/auth/app-config';
import { ExportExcelService } from 'src/app/reporting/services/export-excel.service';
import { MainService } from '../../services/main.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/admin/services/common.service';
import { StationListService } from 'src/app/networks/services/station.service';
import { MatSnackBar } from '@angular/material/snack-bar';
declare const CanvasJS: any;
@Component({
  selector: 'app-mac-consumption-report',
  templateUrl: './mac-consumption-report.component.html',
  styleUrls: ['./mac-consumption-report.component.scss']
})
export class MacConsumptionReportComponent implements OnInit {
  datasource = new MatTableDataSource<any>();
  reportData$ = [];
  items=[];
  station_code:any
  spinner=false;
  totalConsumption:number=0;
  totalUptime:number=0;

  reportForm:FormGroup;
  displayedColumns=['username','mac_address','ip_address','stationip','starttime','last_update','stoptime','uptime','consumption']
  @ViewChild(MatPaginator,{static:true}) paginator:MatPaginator;
  constructor(private mainService:MainService,private snackBar:MatSnackBar,private stationService:StationListService,private commonService:CommonService,private fb:FormBuilder,private exportService: ExportExcelService,private config:AppConfig,public dialogRef: MatDialogRef< MacConsumptionReportComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.reportForm = this.fb.group({
      frm:[new Date(),Validators.required],
      to:[new Date(),Validators.required],
      username:[{disabled:true}],
      breakdown_typ:['1h']

    })
    this.mainService.getReport(this.data['username'],this.data['typ'],this.data['account']).subscribe(data=>{
      this.items=data;
      this.reportData$= data;


      this.totalConsumption=data.reduce((accumulator, currentItem) => accumulator + currentItem['consumption'], 0);
      this.totalUptime=data.reduce((accumulator, currentItem) => accumulator + currentItem['uptime'], 0);
    })
    this.station_code=this.data['station_code']
    if(this.data['user_type']){
      if(this.data['user_type'] === 'DHCP' && this.data['plugin_type'] !== 'STATIC'){
        this.data['username'] = this.data['mac']
      }
    }

  }
  getReport(filename:string){
    console.log(this.reportData$)
    this.exportService.export(this.reportData$,filename);
  }

  getAnalysis(){
    let formData=this.reportForm.getRawValue();
    formData['username']=this.data['username'] //


    this.commonService.cleanUpDate(formData);

    formData['station_code']=this.station_code;
    if(!this.station_code){
      this.snackBar.open("Station declared.Please cleared browser cache and reload page")
    }
    this.mainService.getTraffic(formData).subscribe(data=>{
      let format="HH:MM"
      let intervalType ='hour'
      if (formData['breakdown_typ'] =='1d'){
        format='DD MMM'
        intervalType='day'

      }
      else if (formData['breakdown_typ'] =='1M'){
        format='MMM'
        intervalType ='month'
      }
      // else if (formData['breakdown_typ'] =='1w'){
      //   format='W'
      //   intervalType ='week'
      // }
      else if (formData['breakdown_typ'] =='1y'){
        format='YYYY'
        intervalType ='year'
      }
      this.plotGraph(intervalType,format,data)
    })

  }
  onAnalysisTabClick(e){
    if(e.tab.textLabel == 'Traffic Analysis'){
    if(!this.station_code){
      this.stationService.getSingle(this.data['stationid']).subscribe(data=>{
        this.station_code = data['station']
      })
    }

  }}
plotGraph(intervalType,format,data){
  console.log(data)
  var toolTip = {
    shared: true
  }
  if(!data){

    return false;
  }

  let tx =[]
  let rx =[]
  data.forEach((v,k)=>{
    let r =v
    let drow={}
    let urow={}
    urow['x']=drow['x']=new Date(r['x'])

    drow['y'] =   r['download']=== null ? 0 : parseFloat(r['download'])
    urow['y'] =    r['upload']=== null ? 0 : parseFloat( r['upload'])


    rx.push(drow)
    tx.push(urow)

  })


  console.log(format,"----------->",rx)
  console.log(tx)
  var networkChartOptions = {
    animationEnabled: true,
    theme: "light2",
    title:{
      text: "Network Traffic"
    },
    axisY: {
      suffix: " Kb/s"
    },
    axisX: {
      intervalType: intervalType,
      interval: 1,
			labelFormatter: function (e) {
			return CanvasJS.formatDate( e.value, format);
			},
			labelAngle: -20
		},
    data: [{
      type: "splineArea",
      showInLegend: "true",
      name: "RX",
      color: "#81c784",
       xValueType: "dateTime",
      // xValueFormatString: format,
      // yValueFormatString: "#.## b/s",
      legendMarkerType: "square",
      dataPoints: rx
    },{
      type: "splineArea",
      showInLegend: "true",
      name: "TX",
      color: "#388e3c",
      xValueType: "dateTime",
      // xValueFormatString: format,
      // yValueFormatString: "#.## b/s",
      legendMarkerType: "square",
      dataPoints: tx
    }]
  }

  let c = new CanvasJS.Chart("network", networkChartOptions)
  c.render()
}


}
