import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig } from '../app-config';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  items:any;
  
  constructor(    private route: ActivatedRoute,
    private router: Router,
    private appConfig: AppConfig,
    private fb: FormBuilder,         // {3}
    private authService: AuthService, // {4},
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.items =params;
      this.authService.getAgreement(params['article_type'],params['company']).subscribe(data => {
        this.items = data;
      })
    });
  
  }

}
