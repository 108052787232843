import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject, throwError, timer } from 'rxjs';
import { AppConfig } from 'src/app/auth/app-config';
import * as moment from 'moment';
import { catchError, tap } from 'rxjs/operators';
import { EventSourcePolyfill } from "event-source-polyfill";
import { OnDestroy } from "@angular/core";

// Add the Injectable decorator

export class ListEventSourceService<T> {

  private eventSource: EventSourcePolyfill;
  private readonly dataSubject: Subject<any> = new Subject<any>();
  private readonly TIMEOUT_DURATION: number = 5 * 60 * 1000; // 5 minutes in milliseconds


  // Define a constant for the timeout duration (5 minutes)

  constructor(public http: HttpClient, public config: AppConfig,
    public list_url: string, // Use @Inject decorator to specify injection token for list_url
    public detail_url: string // Use @Inject decorator to specify injection token for detail_url
  ) { }

  getToken() {
    let theToken = localStorage.getItem("token");
    //console.log(`The Token:  ${theToken}`);
    return theToken;
  }

  


  getOltDeviceOptions():Observable<any>{
    return this.http.get<any>(`${this.config.apiUrl}/devicemonitor/getOltOptions/`)
  }

 
 

  validateDeviceToken(deviceId: string): Observable<any> {
  
    return this.http.post<any>(`${this.config.apiUrl}/devicemonitor/wan-configs/`, {id:deviceId}).pipe(
      catchError(error => {
        console.error('Token validation error', error);
        return throwError(error);
      })
    );
  }

  delete(id: string): Observable<T> {
    return this.http.delete<T>(`${this.config.apiUrl}/${this.detail_url}/${id}/`);
  }

  private parseEventData(rawData: string): any {
    try {
      const data = JSON.parse(rawData);
      return data;
    } catch (error) {
      console.error('Error parsing JSON:', error);
      return null;
    }
  }

  unsubscribeFromEvents(): void {
    if (this.eventSource) {
      console.log("called unsubscribe")
      this.eventSource.close();
      this.dataSubject.complete();
    }
  }

  getEventStream(): Observable<any> {
    return this.dataSubject.asObservable().pipe(
      tap((items: any) => {
        console.log(items);
      }));
  }


  getAll(pageNumber: number = 1, pageSize: number = 10, sortByColumn: string = "name", sortAscDesc: string = "asc", filter: string = '', filter_params: any = {}): Observable<any> {
    let lastMessageTime = Date.now();

    const url = `${this.config.apiUrl}/${this.list_url}/`;
    this.eventSource = new EventSourcePolyfill(url, {
      headers: {
        'Authorization': `Bearer ${this.getToken()}`
      }
    });

    return new Observable(observer => {
      this.eventSource.onopen = () => {
        console.log('Connection established successfully');
      };

      this.eventSource.onmessage = (event) => {
        const eventData = this.parseEventData(event.data);
        observer.next(eventData);
        if (eventData.isFinal) {
          console.log('Received final message. Closing connection.', eventData.isFinal);
          this.eventSource.close();
          observer.complete();
        }
      };

      this.eventSource.onerror = (error) => {
        console.error('EventSource error:', error);
        this.eventSource.close();
        observer.error(error);
      };

      const timeoutTimer = timer(0, 1000).subscribe(() => {
        const elapsedTime = Date.now() - lastMessageTime;
        if (elapsedTime >= this.TIMEOUT_DURATION) {
          console.log('No messages received within timeout period. Closing connection.');
          this.eventSource.close();
          observer.complete();
          timeoutTimer.unsubscribe();
        }
      });
    });
  }


  streamOltDeviceInfo(pon_id: string, action: string): Observable<any> {
    const url = `${this.config.apiUrl}/${this.detail_url}/device-config/`;
    const postData = { pon_id: pon_id, action: action };

    return this.http.post(url, postData).pipe(
      catchError(error => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  onuActions(pon_id: string, port: string, action: any, onu_id: any): Observable<any> {
    console.log("pon_id  info ", pon_id)

    const url = `${this.config.apiUrl}/${this.detail_url}/device-config/`;
    const postData = { pon_id: pon_id, port: port, actions: action, id: onu_id };

    return this.http.post(url, postData).pipe(
      catchError(error => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }


  getAllById(id: string, pageNumber: number = 1, pageSize: number = 10, sortByColumn: string = "name", sortAscDesc: string = "asc", filter: string = ''): Observable<any> {
    let params: HttpParams = new HttpParams()
      .set("limit", pageSize.toString())
      .set("offset", (pageNumber * pageSize).toString())
      .set("ordering", `${sortAscDesc == 'desc' ? '-' : ''}${sortByColumn}`)
    if (filter && filter.length) {
      params = params.set("search", filter);
    }
    return this.http.get<T>(`${this.config.apiUrl}/${this.list_url}/${id}/`, {
      params: params
    }).pipe(tap((item: any) => {

    }));

  }
  clean_request(filter, attribute) {
    if (attribute === "frm") {
      if (!filter) {
        return moment("1992-02-25").format("YYYY-MM-DD")
      }
      return filter
    }
    if (attribute === "to") {
      console.log("===========================++")
      console.log(filter)
      if (!filter) {
        let date = new Date()
        date.setDate(date.getDate() + 1)
        console.log(date, "=====================================")
        return moment(date).format("YYYY-MM-DD")
      }
      return filter
    }
    if (attribute === "station") {
      if (!filter) {
        return ""
      }
      return filter
    }
    console.log("=====================================================")
  }
  getSingle(id: string): Observable<T> {
    return this.http.get<T>(`${this.config.apiUrl}/${this.detail_url}/${id}/`);
  }


}
