import { Component, Inject, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-data-calculator',
  templateUrl: './data-calculator.component.html',
  styleUrls: ['./data-calculator.component.scss']
})
export class DataCalculatorComponent implements OnInit {
  current_value:number;
  detailForm:FormGroup;
  constructor(public fb:FormBuilder,private snackBar:MatSnackBar,public dialogRef: MatDialogRef<DataCalculatorComponent>, 
    @Inject(MAT_DIALOG_DATA) public p: any) { }

  ngOnInit() {
    this.detailForm = this.fb.group({
      action:['ADD',Validators.required],
      total_limit:[1,Validators.required],
      total_limit_type:['MB',Validators.required],       
    })
    
    this.current_value = Number(this.p)      
    
  }

  save(e){
    let data = this.detailForm.getRawValue();
    if (data['action'] == "ADD"){
      data['value']=this.current_value + this.calculator(data)
    }else{
      data['value']=this.current_value -  this.calculator(data)
    }

    this.dialogRef.close(data['value']);

  }
  calculator(data){
    console.log(data)
    if(data['total_limit_type'] === "MB"){
      return data['total_limit'] * 1000000
    }
    else if(data['total_limit_type'] === "GB"){
      return data['total_limit'] * 1000000000
    }
   
    else{
      this.snackBar.open("Invalid Entry")
      return 0;
      
    }

  }


}