import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuListItemComponent } from './menu-list-item/menu-list-item.component';
import { MaterialModule } from '../material.module';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
//import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    MenuListItemComponent,
    DeleteDialogComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    //RouterModule.forChild([])
  ],
  exports:[
    MenuListItemComponent,
    //RouterModule
  ],
  providers:[
  ]
})
export class NavigationModule { }
