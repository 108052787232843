import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, map, startWith, switchMap, tap } from 'rxjs/operators';

import { UserService } from 'src/app/admin/services/user.service';
export interface User {
  username: string;
}
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  username = new FormControl();
  options: User[];
  isLoading = false;
  selectedUser: any = "";
  filteredOptions:[];
  @Output() usernameEmit: EventEmitter<any> = new EventEmitter();
  @Input() placeholder:string='Type & Select User acc'
  constructor(public mainService: UserService) {



   }

ngOnInit() {

  this.username.valueChanges
  .pipe(
    filter(res=>{
      return res !=null && res.length >= 2
    }),distinctUntilChanged(),
    debounceTime(800),
    tap(() =>{
      this.filteredOptions = [];
      this.isLoading = true;
    }),
    switchMap((value:string) =>this.mainService.searchService(value)
    .pipe(
      finalize(() =>{
        this.isLoading = false
      }),

    )

  )
  ).subscribe((data:any)=>{
    if(data['results']){
      if(data['results'].length > 0){
        this.filteredOptions =data['results'];
      }else{
        let e={"status":"NEW","phone":this.username.value,"username":this.username.value}
        this.usernameEmit.emit(e);
      }

    }

  })

  }

  displayFn(user?: User): string | undefined {
    return user ? user.username : undefined;
  }

  selectedOption(e) {
    console.log(e);
    e['status']="NO"
    this.usernameEmit.emit(e);
  }
//   keydown(k) {
//     this.usernameEmit.emit(k);
// }
}

