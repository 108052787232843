import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'src/app/auth/app-config';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket'; 
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StationReportService {
  public socket$!: WebSocketSubject<any>;
  private data:string[] = [];
  constructor(public http: HttpClient, public config: AppConfig) { 

  }

  connect(station_code:string):void{
    this.socket$ = webSocket(this.config.wsocket+"/ws/interfaces/"+station_code+"/");

    // You can subscribe to messages here if needed
    this.socket$.subscribe(
      (message) => console.log('Received message:', message),
      (error) => console.error('WebSocket error:', error),
      () => console.log('WebSocket connection closed')
    );
  }
  disconnect(){
    this.socket$.complete();
  }
  iSConnected():boolean{
    return (this.socket$ == null ? false : !this.socket$.closed);
  }
  onMessage():Observable<any>{
    return this.socket$.asObservable().pipe(
      map(message => message)
    )
  }
  send(message:any){
    console.log(message,"Grop deis")
    this.socket$.next(message);
  }

}

