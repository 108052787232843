import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppConfig } from 'src/app/auth/app-config';
import { Plan } from 'src/app/networks/models/plan';
import { Station } from 'src/app/networks/models/station';
import { PlanService } from 'src/app/networks/services/plan.service';
import { StationListService } from 'src/app/networks/services/station.service';

@Component({
  selector: 'app-contention-detail',
  templateUrl: './contention-detail.component.html',
  styleUrls: ['./contention-detail.component.scss']
})
export class ContentionDetailComponent implements OnInit {
  plans:Plan;
  stations:Station
  detailForm:FormGroup;
  @ViewChild(MatPaginator,{static:true}) paginator:MatPaginator;
  constructor(private stationService:StationListService,
    public dialogRef: MatDialogRef<ContentionDetailComponent>,
    private snackBar:MatSnackBar,
    private planService:PlanService,private fb:FormBuilder,private config:AppConfig) { }


  ngOnInit(): void {
    this.stationService.getMinimizedStation().subscribe(data => {
      this.stations = data['results'];
      
    });

    this.planService.getAll(0,1000).subscribe(item=>{
      this.plans= item['results']
    });

    this.detailForm = this.fb.group({
      station:['',Validators.required],
      plan:['',Validators.required],
      action:['Install',Validators.required],
      delete_parent_queue_tree:[false,Validators.required]
      //introduce rate of querying
    })

  }

  closeDialog(e){
    this.config.hide(e)
    this.stationService.runContentionManagement(this.detailForm.value).subscribe(data=>{
      this.snackBar.open(data['message'])
      this.config.show(e);
    },error=>{
      this.config.show(e);
      this.snackBar.open("An error occurred")
    })

  }




}
