import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from 'src/app/auth/app-config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(public http: HttpClient, public config: AppConfig){ }
  uploadDocument(doc: any, url: any): Observable<any> {
    return this.http.post<any>(`${this.config.apiUrl}${url}`, doc, {
      reportProgress: true,
      observe: 'events'
    });
  }
  downloadCSV(url: string): Observable<Blob> {
    return this.http.get(`${this.config.apiUrl}${url}`, {
      responseType: 'blob'
    });
  }
  updateDocument(id:string,doc:any, url: any): Observable<any> {
    // let headers: HttpHeaders = new HttpHeaders();
    // headers = headers.append('Content-type', 'multipart/form-data');
    // headers = headers.append('Content-Disposition', 'attachment; filename=file');
    // headers = headers.append('filename', 'file')

    let options={
      reportProgress: true,
      // headers: headers
    }
    return this.http.put<any>(`${this.config.apiUrl}${url}${id}/`, doc, options);
  }
  cleanUpDate(formData: any) {
    Object.keys(formData).forEach(key => {
        if (Object.prototype.toString.call(formData[key]) === '[object Date]') {
            // console.log(`Prop: ${key}, Value: ${formData[key]}, Type: ${typeof (formData[key])}`)

            //  alert(moment(formData[key]).format("YYYY-MM-DD"))
            console.log(formData[key])
            formData[key] = moment(formData[key]).format("YYYY-MM-DD HH:mm:ss");
            //formData[key] = moment(formData[key]).format("YYYY-MM-DDTHH:mm:ss");
        }
    });
}
cleanRangeDate(formData: any) {
  Object.keys(formData).forEach(key => {
      if (Object.prototype.toString.call(formData[key]) === '[object Date]') {
          // console.log(`Prop: ${key}, Value: ${formData[key]}, Type: ${typeof (formData[key])}`)

          //  alert(moment(formData[key]).format("YYYY-MM-DD"))
          console.log(formData[key])
          formData[key] = moment(formData[key]).format("YYYY-MM-DD");
          //formData[key] = moment(formData[key]).format("YYYY-MM-DDTHH:mm:ss");
      }
  });
}

formatPhone(phone:string):boolean{
  // use specialized librart
  console.log(phone.length)
  if (phone.length >= 10){
    return true;
  }else{
    return false
  }

}
}




