
import { throwError as observableThrowError, Observable, throwError, BehaviorSubject } from 'rxjs';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { AuthService } from "./auth.service";
import { Injectable, Injector } from "@angular/core";
import { catchError, tap, switchMap, filter, take, retry, retryWhen, concatMap, delay } from "rxjs/operators";
import { error } from "protractor";
import { Router } from "@angular/router";
import { ErrorDialogService } from './services/error-dialog.service';
import { of } from 'rxjs';
import { Tr069Service } from '../common/services/tr069.service';
import { AppConfig } from './app-config';
export const retryCount = 3;
export const retryWaitMilliSeconds = 1000;
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  //constructor(private router: Router, private authService: AuthService) { }
  constructor(private router: Router, private injector: Injector, private errorDialogService: ErrorDialogService,     private appConfig: AppConfig // Inject AppConfig here
  ) { }
  private inflightAuthRequest: Observable<any> = null;
  private inflightTr069AuthRequest: Observable<any> = null;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authService = this.injector.get(AuthService);
    // var authReq = this.addAuthToken(req);
    // Determine which base URL to use based on the request URL
    let authReq: HttpRequest<any>;
    if (req.url.startsWith(this.appConfig.tr069_baseUrl) || req.url.startsWith(this.appConfig.tr069_apiUrl)) {
      authReq = this.addTr069Headers(req);
    } else { 
      authReq = this.addAuthToken(req);
    }

    // if (!window.navigator.onLine) {
    //   // if there is no internet, throw a HttpErrorResponse error
    //   // since an error is thrown, the function will terminate here
    //   let p: string[]=["Dear Network Admin.Connection to the internet has failed.Please ensure you have internet.Thanks"]
    //   this.errorDialogService.openDialog(p);
    //   const error = {
    //     status: 0,
    //     error: {
    //       description: 'Check Internet Connectivity!'
    //     },
    //     statusText: 'Check Internet Connectivity!'
    //   };
    //   return throwError(new HttpErrorResponse(error));
    // }

    return next.handle(authReq)
      .pipe(
        retryWhen(error=>
          error.pipe(
            concatMap((error,count) => {
              if (count <=retryCount && error.status == 0){
                return of(error);
              }
              return throwError(error);
            }),
            delay(retryWaitMilliSeconds)
          )
          ),
        catchError(error => {
          if (error instanceof HttpErrorResponse) {
            //if (error.status == 401 && error.headers.has("Token-Expired")) {
            // if (error.status == 0) {

            //   retry(retryCount);
            // }
            if (error.status == 401) {
              console.log("Status 401");
              if (authReq.url.startsWith(this.appConfig.tr069_baseUrl) || authReq.url.startsWith(this.appConfig.tr069_apiUrl)) {
                return this.handleTr069AuthError(authReq, next);
              }
              else  if (!this.inflightAuthRequest) {
                this.inflightAuthRequest = authService.refreshToken();
                console.log("inflight detected..")
                if (!this.inflightAuthRequest) {
                  authService.logout();
                  this.router.navigate(['/auth/login']);
                  return throwError(error);
                }
              
                else {
                  return this.inflightAuthRequest.pipe(
                    switchMap((newToken: string) => {

                      // unset inflight request
                      this.inflightAuthRequest = null;

                      // resend the request
                      var authReq = this.addAuthToken(req);
                      return next.handle(authReq);
                    }),
                    catchError(error => {
                      console.log("got weird error..")
                      authService.logout();
                      this.router.navigate(['/auth/login']);
                      return throwError(error);
                    })
                  )
                }
              }
              else {
                authService.logout();
                this.router.navigate(['/auth/login']);
              }
            }
            else {
              //window.alert("other error occured:" + error.status );
              let messages = this.getErrorMessage(error);
              //window.alert(message);
              this.errorDialogService.openDialog(messages);
              //console.log("Error info:", error.error)
              return observableThrowError(error);
            }
          }
          return observableThrowError(error);
        })
      )
  }

  private handleTr069AuthError(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authService = this.injector.get(AuthService);

    if (!this.inflightTr069AuthRequest) {
      this.inflightTr069AuthRequest = authService.refreshTr069Token().pipe(
        tap(() => this.inflightTr069AuthRequest = null),
        catchError(error => {
          authService.logout();
          this.router.navigate(['/auth/login']);
          this.inflightTr069AuthRequest = null;
          return throwError(error);
        })
      );
    }

    return this.inflightTr069AuthRequest.pipe(
      switchMap(() => next.handle(this.addTr069Headers(req)))
    );
  }
  

  addAuthToken(req: HttpRequest<any>) {
    const authService = this.injector.get(AuthService);
    const token = authService.getToken();
    var ignore = req.url.includes("refresh") || req.url.includes("obtain")
    if (token && !ignore) {
      //console.log("Adding token");
      const cloned = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`)
      });
      return cloned;
    }
    else {
      return req;
    }
  }

  private addTr069Headers(req: HttpRequest<any>): HttpRequest<any> {
    const authService = this.injector.get(AuthService);
    const tr069Token = authService.getTr069Token();
    if (tr069Token) {
      const headers = {
        Authorization: `${tr069Token}`, // Adjust as per your TR-069 token format
      };
      return req.clone({
        setHeaders: headers,
      });
    }
    return req;
  }

  getErrorMessage(error: HttpErrorResponse): string[] {
    let errorMessage: string[] = [];
    if (error.status != 500 && error.error  && typeof (error.error) !== 'string') {
      //console.log("Errors: ", Object.values(error.error))
      // if(error.detail){

      // }
      let errors = Object.values(error.error);
      console.log("Error detail.................................")
      console.log(error)

      for (let index in errors) {
        let messages = errors[index];
        console.log("Messages: ",messages)

        // alert(typeof(messages))
        if (typeof (messages) == 'string') {
          errorMessage.push(messages);

        }
        else {
          for (let message in messages as any[]) {
            errorMessage.push(messages[message]);
          }
        }
      }
    }
    if (error.status === 500 && typeof(error.error) != 'string') {
      //console.log("Error details:")
      //console.log(error)
      if ('detail' in error.error) {
        errorMessage.push(error.error['detail']);
      }
    }
    if (errorMessage.length == 0) {
      errorMessage.push("One or more issues occurred. Please contact Admin for assistance");
    }
    return errorMessage;
  }
}
