import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseListComponent, DETAIL_ROUTE_SECTION } from './base-list/base-list.component';
import { UserComponent } from './components/user/user.component';
import { TransactionIdComponent } from './components/transaction-id/transaction-id.component';
import { MaterialModule } from '../material.module';
import { LoanTransactionIdComponent } from './components/loan-transaction-id/loan-transaction-id.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ProgressComponent } from './progress/progress.component';
import { NoDblClickDirective, DebounceClickDirective } from './directives/no-dbl-click.directive';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';

import { PromptComponent } from './prompt/prompt.component';
import { BandwidthToolComponent } from './components/bandwidth-tool/bandwidth-tool.component';
import { NetworkAttributeDetailComponent } from './network-attribute-detail/network-attribute-detail.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { PeriodCalculatorComponent } from './period-calculator/period-calculator.component';
import { DataCalculatorComponent } from './data-calculator/data-calculator.component';
import { PromptQuestionComponent } from './components/prompt-question/prompt-question.component';
import { UsernameConsumptionReportComponent } from './components/username-consumption-report/username-consumption-report.component';
import { MacConsumptionReportComponent } from './components/mac-consumption-report/mac-consumption-report.component';
import { PdfdownloadDirective } from './directives/pdfdownload.directive';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { ContentionDetailComponent } from './components/contention-detail/contention-detail.component';

import { StationReportComponent } from './components/station-report/station-report.component';
import { FilterPipe } from './filter.pipe';
import { NetpapBiometricUserAttributeDetailComponent } from './netpapbiometric-user-attribute-detail/netpapbiometric-user-attribute-detail.component';
import { ChunkPipe } from './pipes/chunk.pipe';
import { DeviceConfigAttributesComponent } from './device-config-attributes/device-config-attributes.component';
import { BaseListTr069Component } from './base-list-tr069/base-list-tr069.component';


@NgModule({
  declarations: [
    BaseListComponent, UserComponent, TransactionIdComponent, LoanTransactionIdComponent, FileUploadComponent, ProgressComponent,
     NoDblClickDirective,
    DebounceClickDirective,
    NoDblClickDirective,
    ConfirmDialogComponent,
    PromptComponent,
    BandwidthToolComponent,
    NetworkAttributeDetailComponent,
    NetpapBiometricUserAttributeDetailComponent,
    
    PeriodCalculatorComponent,
    DataCalculatorComponent,
    PromptQuestionComponent,
    UsernameConsumptionReportComponent,
    MacConsumptionReportComponent,
    PdfdownloadDirective,
    SearchBarComponent,
    ContentionDetailComponent,
    StationReportComponent,
    FilterPipe,
    ChunkPipe,
    DeviceConfigAttributesComponent,
    BaseListTr069Component,


    // BaseDetailComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ],
  exports: [

    UserComponent,
    TransactionIdComponent,
    LoanTransactionIdComponent,
    FileUploadComponent,
    ProgressComponent,
    BandwidthToolComponent,
    ContentionDetailComponent,
    PeriodCalculatorComponent,
    DataCalculatorComponent,
    NetworkAttributeDetailComponent,
    NetpapBiometricUserAttributeDetailComponent,
    PromptQuestionComponent,
    StationReportComponent,
    ChunkPipe,

    DebounceClickDirective,
    NoDblClickDirective,
    PdfdownloadDirective,SearchBarComponent
  ],
  providers: [{provide: DETAIL_ROUTE_SECTION, useValue: ''}]
})
export class AppHelperModule { }
