import{ Chart} from 'chart.js';
import _ from 'lodash';



Chart.elements.Rectangle.prototype.draw = function() {
  var ctx = this._chart.ctx;
  var vm = this._view;
  var left, right, top, bottom, signX, signY, borderSkipped, radius;
  var borderWidth = vm.borderWidth;
  // Set Radius Here
  // If radius is large enough to cause drawing errors a max radius is imposed
  var cornerRadius = 6;

  if (!vm.horizontal) {
    // bar
    left = vm.x - vm.width / 2;
    right = vm.x + vm.width / 2;
    top = vm.y;
    bottom = vm.base;
    signX = 1;
    signY = bottom > top ? 1 : -1;
    borderSkipped = vm.borderSkipped || "bottom";
  } else {
    // horizontal bar
    left = vm.base;
    right = vm.x;
    top = vm.y - vm.height / 2;
    bottom = vm.y + vm.height / 2;
    signX = right > left ? 1 : -1;
    signY = 1;
    borderSkipped = vm.borderSkipped || "left";
  }

  // Canvas doesn't allow us to stroke inside the width so we can
  // adjust the sizes to fit if we're setting a stroke on the line
  if (borderWidth) {
    // borderWidth shold be less than bar width and bar height.
    var barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom));
    borderWidth = borderWidth > barSize ? barSize : borderWidth;
    var halfStroke = borderWidth / 2;
    // Adjust borderWidth when bar top position is near vm.base(zero).
    var borderLeft = left + (borderSkipped !== "left" ? halfStroke * signX : 0);
    var borderRight =
      right + (borderSkipped !== "right" ? -halfStroke * signX : 0);
    var borderTop = top + (borderSkipped !== "top" ? halfStroke * signY : 0);
    var borderBottom =
      bottom + (borderSkipped !== "bottom" ? -halfStroke * signY : 0);
    // not become a vertical line?
    if (borderLeft !== borderRight) {
      top = borderTop;
      bottom = borderBottom;
    }
    // not become a horizontal line?
    if (borderTop !== borderBottom) {
      left = borderLeft;
      right = borderRight;
    }
  }

  ctx.beginPath();
  ctx.fillStyle = vm.backgroundColor;
  ctx.strokeStyle = vm.borderColor;
  ctx.lineWidth = borderWidth;

  // Corner points, from bottom-left to bottom-right clockwise
  // | 1 2 |
  // | 0 3 |
  var corners = [[left, bottom], [left, top], [right, top], [right, bottom]];

  // Find first (starting) corner with fallback to 'bottom'
  var borders = ["bottom", "left", "top", "right"];
  var startCorner = borders.indexOf(borderSkipped, 0);
  if (startCorner === -1) {
    startCorner = 0;
  }

  function cornerAt(index) {
    return corners[(startCorner + index) % 4];
  }

  // Draw rectangle from 'startCorner'
  var corner = cornerAt(0);
  ctx.moveTo(corner[0], corner[1]);

  for (var i = 1; i < 4; i++) {
    corner = cornerAt(i);
    let nextCornerId = i + 1;
    if (nextCornerId === 4) {
      nextCornerId = 0;
    }

    // let nextCorner = cornerAt(nextCornerId);

    let width = corners[2][0] - corners[1][0];
    let height = corners[0][1] - corners[1][1];
    let x = corners[1][0];
    let y = corners[1][1];
    // eslint-disable-next-line
    var radius: any = cornerRadius;

    // Fix radius being too large
    if (radius > height / 2) {
      radius = height / 2;
    }
    if (radius > width / 2) {
      radius = width / 2;
    }

    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    ctx.lineTo(x + width, y + height - radius);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    ctx.lineTo(x + radius, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
  }

  ctx.fill();
  if (borderWidth) {
    ctx.stroke();
  }
};

var mode = 'light';//(themeMode) ? themeMode : 'light';
var fonts = {
  base: 'Open Sans'
}

// Colors
var colors = {
  gray: {
    100: '#f6f9fc',
    200: '#e9ecef',
    300: '#dee2e6',
    400: '#ced4da',
    500: '#adb5bd',
    600: '#8898aa',
    700: '#525f7f',
    800: '#32325d',
    900: '#212529'
  },
  theme: {
    'default': '#172b4d',
    'primary': '#5e72e4',
    'secondary': '#f4f5f7',
    'info': '#11cdef',
    'success': '#2dce89',
    'danger': '#f5365c',
    'warning': '#fb6340'
  },
  black: '#12263F',
  white: '#FFFFFF',
  transparent: 'transparent',
};

 export function chartOptions() {

  // Options
  var options = {
    defaults: {
      global: {
        responsive: true,
        maintainAspectRatio: false,
        defaultColor: (mode == 'dark') ? colors.gray[700] : colors.gray[600],
        defaultFontColor: (mode == 'dark') ? colors.gray[700] : colors.gray[600],
        defaultFontFamily: fonts.base,
        defaultFontSize: 13,
        layout: {
          padding: 0
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            usePointStyle: true,
            padding: 16
          }
        },
        elements: {
          point: {
            radius: 0,
            backgroundColor: colors.theme['primary']
          },
          line: {
            tension: .4,
            borderWidth: 4,
            borderColor: colors.theme['primary'],
            backgroundColor: colors.transparent,
            borderCapStyle: 'rounded'
          },
          rectangle: {
            backgroundColor: colors.theme['warning']
          },
          arc: {
            backgroundColor: colors.theme['primary'],
            borderColor: (mode == 'dark') ? colors.gray[800] : colors.white,
            borderWidth: 4
          }
        },
        tooltips: {
          enabled: true,
          mode: 'index',
          intersect: false,
        }
      },
      doughnut: {
        cutoutPercentage: 83,
        legendCallback: function(chart) {
          var data = chart.data;
          var content = '';

          data.labels.forEach(function(label, index) {
            var bgColor = data.datasets[0].backgroundColor[index];

            content += '<span class="chart-legend-item">';
            content += '<i class="chart-legend-indicator" style="background-color: ' + bgColor + '"></i>';
            content += label;
            content += '</span>';
          });

          return content;
        }
      }
    }
  }

  // yAxes
  Chart.scaleService.updateScaleDefaults('linear', {
    gridLines: {
      borderDash: [2],
      borderDashOffset: [2],
      color: (mode == 'dark') ? colors.gray[900] : colors.gray[300],
      drawBorder: false,
      drawTicks: false,
      drawOnChartArea: (mode == 'dark') ? false : true,
      lineWidth: 1,
      zeroLineWidth: 0,
      zeroLineColor: (mode == 'dark') ? colors.gray[900] : colors.gray[300],
      zeroLineBorderDash: [2],
      zeroLineBorderDashOffset: [2]
    },
    ticks: {
      beginAtZero: true,
      padding: 10,
      callback: function(value) {
        if (!(value % 10)) {
          return value
        }
      }
    }
  });

  // xAxes
  Chart.scaleService.updateScaleDefaults('category', {
    gridLines: {
      drawBorder: false,
      drawOnChartArea: false,
      drawTicks: false
    },
    ticks: {
      padding: 20
    },
    datasets: [{
        maxBarThickness: 10
    }]
  });

  return options;

}

export const parseOptions = (parent, options) => {
		for (var item in options) {
			if (typeof options[item] !== 'object') {
				parent[item] = options[item];
			} else {
				parseOptions(parent[item], options[item]);
			}
		}
	}

export const chartExample1 = {
  options: {
    scales: {
      yAxes: [{
        gridLines: {
          color: colors.gray[900],
          zeroLineColor: colors.gray[900],
          drawOnChartArea: false
        },
        ticks: {
          callback: function(value) {
            if (!(value % 10)) {
              return value ;
            }
          }
        }
      }]
    }
  },
  data: {
    labels: [],
    datasets: [
    
    // {
    //   label: 'Active Users',
    //   data: [0, 26, 12, 39, 11, 45, 21, 69, 6],
    //   backgroundColor: 'rgba(54, 162, 235, 0.5)',

    // },
    // {
    //   label: 'Expired Users',
    //   data: [0, 5, 9, 0, 9, 4, 2, 6, 1], 
    //   borderColor: 'red',
    //   backgroundColor: 'rgba(255, 0, 0, 0.5)',

    // }
  ]
  }
}

export const chartExample6 = {
  options: {
    scales: {
      yAxes: [{
        gridLines: {
          color: colors.gray[900],
          zeroLineColor: colors.gray[900],
          drawOnChartArea: false
        },
        ticks: {
          callback: function(value) {
            if (!(value % 10)) {
              return value ;
            }
          }
        }
      }]
    }
  },
  data: {
    labels: [],
    datasets: [
    
    // {
    //   label: 'Active Users',
    //   data: [0, 26, 12, 39, 11, 45, 21, 69, 6],
    //   backgroundColor: 'rgba(54, 162, 235, 0.5)',

    // },
    // {
    //   label: 'Expired Users',
    //   data: [0, 5, 9, 0, 9, 4, 2, 6, 1], 
    //   borderColor: 'red',
    //   backgroundColor: 'rgba(255, 0, 0, 0.5)',

    // }
  ]
  }
}

export const chartExample2 = {
  options: {
    scales: {
      x: {
        display: false,  // Hide x-axis
      },
      y: {
        display: false,  // Hide y-axis
      },
      yAxes: [
        {
          ticks: {
            callback: function(value) {
              if (!(value % 10)) {
                //return '$' + value + 'k'
                return value;
              }
            }
          }
        }
      ]
    },
    tooltips: {
      callbacks: {
        label: function(item, data) {
          var label = data.datasets[item.datasetIndex].label || "";
          var yLabel = item.yLabel;
          var content = "";
          if (data.datasets.length > 1) {
            content += label;
          }
          content += yLabel;
          return content;
        }
      }
    }
  },
  data: {
    labels: [],
    datasets: [
      // {
        
      //   maxBarThickness: 10
      // }
    ]
  }
}
export const chartExample7 = {
  options: {
    scales: {
      x: {
        display: false,  // Hide x-axis
      },
      y: {
        display: false,  // Hide y-axis
      },
      yAxes: [
        {
          ticks: {
            callback: function(value) {
              if (!(value % 10)) {
                //return '$' + value + 'k'
                return value;
              }
            }
          }
        }
      ]
    },
    tooltips: {
      callbacks: {
        label: function(item, data) {
          var label = data.datasets[item.datasetIndex].label || "";
          var yLabel = item.yLabel;
          var content = "";
          if (data.datasets.length > 1) {
            content += label;
          }
          content += yLabel;
          return content;
        }
      }
    }
  },
  data: {
    labels: [],
    datasets: [
      // {
        
      //   maxBarThickness: 10
      // }
    ]
  }
}
export const chartExample4 = {
  options: {
    scales: {
      yAxes: [
        {
          ticks: {
            callback: function(value) {
              if (!(value % 10)) {
                return value;
              }
            }
          }
        }
      ]
    },
    tooltips: {
      callbacks: {
        label: function(item, data) {
          var label = data.labels[item.index];
          var value = data.datasets[item.datasetIndex].data[item.index];
          return `${label}: ${value}`;
        }
      }
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        generateLabels: function(chart) {
          var data = chart.data;
          if (data.labels.length && data.datasets.length) {
            return data.labels.map(function(label, i) {
              var dataset = data.datasets[0];
              var backgroundColor = dataset.backgroundColor[i];
              return {
                text: label,
                fillStyle: backgroundColor,
                strokeStyle: backgroundColor,
                lineWidth: 2,
                hidden: isNaN(dataset.data[i]) || dataset.data[i] === 0
              };
            });
          }
          return [];
        },
      },
    },
  },
  data: {
    labels: ["Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Vouchers",
        data: [25, 20, 30, 22, 17, 29],
        backgroundColor: ['#800003', '#36A2EB', '#FFCE56', '#4CAF50', '#9966FF', '#FF8C00']
      }
    ]
  }
};

export const chartExample3 = {
  options: {
    scales: {
      yAxes: [
        {
          ticks: {
            callback: function(value) {
              if (!(value % 10)) {
                return value;
              }
            }
          }
        }
      ]
    },
    plugins: {
      datalabels: {
        color: 'white',
        formatter: (value, context) => {
          let label = context.chart.data.labels[context.dataIndex];
          let dataset = context.chart.data.datasets[0];
          let total = dataset.data.reduce((accumulator, currentValue) => accumulator + currentValue);
          let percentage = ((value / total) * 100).toFixed(2) + "%";
          return `${label}\n${value} (${percentage})`;
        }
      }
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          let label = data.labels[tooltipItem.index] || '';
          let datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
          let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return `${label}: ${value}`;
        }
      }
    },
    legend: {
      display: true,
      position: 'left',
      labels: {
        generateLabels: function(chart) {
          var data = chart.data;
          if (data.labels.length && data.datasets.length) {
            return data.labels.map(function(label, i) {
              var dataset = data.datasets[0];
              var backgroundColor = dataset.backgroundColor[i];
              return {
                text: label,
                fillStyle: backgroundColor,
                strokeStyle: backgroundColor,
                lineWidth: 2,
                hidden: isNaN(dataset.data[i]) || dataset.data[i] === 0
              };
            });
          }
          return [];
        }
      }
    }
  },
  data: {
    labels: ["Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "New Users",
        data: [15, 20, 30, 22, 7, 30],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4CAF50', '#9966FF', '#FF8C00']
      }
    ]
  }
};

export const chartExample5 = {
  options: {
    scales: {
      yAxes: [
        {
          ticks: {
            callback: function(value) {
              if (!(value % 10)) {
                return value;
              }
            }
          }
        }
      ]
    },
    plugins: {
      datalabels: {
        color: 'white',
        formatter: (value, context) => {
          let label = context.chart.data.labels[context.dataIndex];
          let dataset = context.chart.data.datasets[0];
          let total = dataset.data.reduce((accumulator, currentValue) => accumulator + currentValue);
          let percentage = ((value / total) * 100).toFixed(2) + "%";
          return `${label}\n${value} (${percentage})`;
        }
      }
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          let label = data.labels[tooltipItem.index] || '';
          let datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
          let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return `${label}: ${value}`;
        }
      }
    },
    legend: {
      display: true,
      position: 'right',
      labels: {
        generateLabels: function(chart) {
          var data = chart.data;
          if (data.labels.length && data.datasets.length) {
            return data.labels.map(function(label, i) {
              var dataset = data.datasets[0];
              var backgroundColor = dataset.backgroundColor[i];
              return {
                text: label,
                fillStyle: backgroundColor,
                strokeStyle: backgroundColor,
                lineWidth: 2,
                hidden: isNaN(dataset.data[i]) || dataset.data[i] === 0
              };
            });
          }
          return [];
        }
      }
    }
  },
  data: {
    // labels: ["Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    labels: [],
    datasets: [
      // {
      //   label: "New Users",
      //   data: [15, 20, 30, 22, 7, 30],
      //   backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4CAF50', '#9966FF', '#FF8C00']
      // }
    ]
  }
};

const DATA_COUNT = 1;
const NUMBER_CFG = {count: DATA_COUNT, min: 10, max: 100};

export const chartExample8 = {
  options: {
    scales: {
      x: {
        stacked: false,
      },
      y: {
        stacked: false
      }
      // yAxes: [{
      //   gridLines: {
      //     color: colors.gray[900],
      //     zeroLineColor: colors.gray[900],
      //     drawOnChartArea: false
      //   },
      //   ticks: {
      //     callback: function(value) {
      //       if (!(value % 10)) {
      //         return value ;
      //       }
      //     }
      //   }
      // }]
    },
    
    legend: {
      display: true,
      position: 'top',
      
    }
  },
  data: {
    labels: [],
    datasets: [
      {
        label:[],
        data:[],
        minBarLength: 2,
        backgroundColor: ['lightgreen', '#36A2EB', '#FF6384']
      }
      // {
      //   label: 'Online',
      //   backgroundColor: 'lightgreen', // Background color for the "Online" bar
      //   data: [], // Will be populated dynamically
      // },
      // {
      //   label: 'All',
      //   backgroundColor: 'blue', // Background color for the "All" bar
      //   data: [], // Will be populated dynamically
      // }
    ]
  }
};

export const chartExample9   = {
  options: {
    scales: {
      yAxes: [
        {
          gridLines: {
            color: colors.gray[900],
            zeroLineColor: colors.gray[900],
            drawOnChartArea: false
          },
          ticks: {
            callback: function(value) {
              if (!(value % 10)) {
                return value ;
              }
            }
          }
        }
      ]
    },
   
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          let label = data.labels[tooltipItem.index] || '';
          let datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
          let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return `${label}: ${value}`;
        }
      }
    },
   
  },
  data: {
    labels: ["Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    // labels: [],
    datasets: [
      {
        label: "Olt Device",
        data: [15, 20, 30, 22, 7, 30],
        borderColor: ['#FF6384'],
        backgroundColor: ['rgba(255, 99, 132, 0.5)'],
        
      }
    ]
  }
};

// export const chartExample10   = {
//   options: {
//     scales: {
//       yAxes: [
//         {
//           gridLines: {
//             color: colors.gray[900],
//             zeroLineColor: colors.gray[900],
//             drawOnChartArea: false
//           },
//           ticks: {
//             callback: function(value) {
//               if (!(value % 10)) {
//                 return value ;
//               }
//             }
//           }
//         }
//       ]
//     },
   
//     tooltips: {
//       callbacks: {
//         label: function(tooltipItem, data) {
//           let label = data.labels[tooltipItem.index] || '';
//           let datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
//           let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
//           return `${label}: ${value}`;
//         }
//       }
//     },
   
//   },
//   data: {
//     // labels: ["Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
//     labels: [],
//     datasets: [
//       // {
//       //   label: "ONU Device",
//       //   // data: [15, 20, 30, 22, 7, 30],
//       //   borderColor: ['green'],
//       //   backgroundColor: ['#71d191'],
        
//       // }
//     ]
//   }
// };

export const chartExample10 = {
  options: {
    scales: {
      xAxes: [{
        type: 'time', // Assuming x-axis is time-based
        time: {
          unit: 'minute' // Adjust as per your data granularity
        },
        gridLines: {
          color: colors.gray[900],
          zeroLineColor: colors.gray[900],
          drawOnChartArea: false
        }
      }],
      yAxes: [{
        gridLines: {
          color: colors.gray[900],
          zeroLineColor: colors.gray[900],
          drawOnChartArea: false
        }
      }]
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';
          let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return `${label}: ${value.y.toFixed(2)}`; // Display y-value rounded to 2 decimal places
        }
      }
    }
  },
  data: {
    labels: [], // Placeholder for x-axis labels (if needed)
    datasets: [
      {
        label: 'Rx', // Label for Rx dataset
        borderColor: 'green',
        backgroundColor: '#71d191',
        fill: true, // No fill for line chart
        data: [] // Initialize empty array for Rx data
      },
      {
        label: 'Tx', // Label for Tx dataset
        borderColor: 'blue',
        backgroundColor: '#71d191',
        fill: true, // No fill for line chart
        data: [] // Initialize empty array for Tx data
      }
    ] // Initialize empty datasets array
  }
};
