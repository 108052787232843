import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dash-test',
  templateUrl: './dash-test.component.html',
  styleUrls: ['./dash-test.component.scss']
})
export class DashTestComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
