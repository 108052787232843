
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AuthService } from './../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/common/components/user/user.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppConfig } from '../app-config';
import { ValidateUsername } from 'src/app/validators/username.validator';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { Company } from 'src/app/admin/model/company';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  register: FormGroup;                // {1}
  private formSubmitAttempt: boolean; // {2}
  loading = false;
  username: string;
  returnUrl: string;
  registerStatus = false;
  options:  Company[];
  security_code = false;
  filteredOptions: Observable<Company[]>;

  company = new FormControl();
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appConfig: AppConfig,
    private fb: FormBuilder,         // {3}
    private authService: AuthService, // {4},
    private snackBar: MatSnackBar
  ) {

    this.filteredOptions = this.company.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => this._filter(name))
      );
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    if(this.authService.isMobile()){
      this.router.navigate(['/client/mobile/login'])
     }
    this.form = this.fb.group({     // {5}
      username: ['', [Validators.required]],
      password: ['', Validators.required],

    });
    this.register = this.fb.group({
      username: ['', [Validators.required]],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', Validators.required],
      email2: ['', Validators.required],
      dob: ['', Validators.required],
      gender: ['', Validators.required],
      other_name: ['', Validators.required],
      id_number: ['', Validators.required],
      security_token: ['']
    });

    this.authService.logout();  // let user redirected be completed logged out..reset menus

    // this.authService.getCompanies().subscribe(data => {
    //   this.options = data['results']
    // });


  }
  displayFn(c?: Company): string | undefined {

    return c ? c.name : undefined;
  }
  private _filter(name: string): Company[] {
    console.log('hehe',name)
    const filterValue = name.toLowerCase();
    if (this.options) {
      if (filterValue) {

        const k =  filterValue;

        const d = this.options.filter(option => option.name.toLowerCase().indexOf(k) === 0);
        console.log(d);
        return d;
      } else {

      }

    } else {
      // this.authUser.getCompanies().subscribe(data => {
      //   this.options = data['results']
      // });
    }
  }
  selectedOption(val){
    this.register.get('security_token').setValue(val['security_token']);
    this.security_code = true;
  }

  isFieldInvalid(field: string) { // {6}
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }
  RegisterFieldInvalid(field: string) { // {6}
    return (
      (!this.register.get(field).valid && this.register.get(field).touched) ||
      (this.register.get(field).untouched)
    );
  }
  confirmEmail(field: string) {
    return (this.register.get('email2').value === this.register.get('email').value);
  }

  onSubmit() {

    if (!this.form.invalid) {
      console.log('logging in..')
      this.loading = true;


      this.authService.login(this.form.value).subscribe(
        data => {

        },
        error => {
          // this.alertService.error('Username or password is incorrect');
          console.log('Username or password is incorrect');
          // this.form.get('username').setValue(this.username);
          this.loading = false;
          // alert('cannot login');
        });
    } else {
      this.snackBar.open('There was a form error');
    }
    this.formSubmitAttempt = true;             // {8}
  }

  onRegister() {
    if (this.register.get('email').value !== this.register.get('email2').value) {
      this.snackBar.open('Emails do not match');
      return false;
    }


    if (this.register.valid) {

      this.authService.registerUser(this.register.value).subscribe(
        data => {
          console.log(this.registerStatus);
          console.log('Register true');
          this.registerStatus = true;
          this.snackBar.open('Congratulations!!.Registration was successful');
        },
        error => {
          this.register.get('username').setValue(this.username);
        }
      )
    }
  }




}


