import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AppConfig } from '../../app-config';
import { AuthService } from '../../auth.service';
import { User } from '../../user';

@Component({
  selector: 'app-mobile-login',
  templateUrl: './mobile-login.component.html',
  styleUrls: ['./mobile-login.component.scss']
})
export class MobileLoginComponent implements OnInit {
  detailForm:FormGroup;
  constructor(private fb:FormBuilder,private config:AppConfig,
    private router:Router,
    private snackBar:MatSnackBar,private authService:AuthService) { }

  ngOnInit() {
    this.detailForm = this.fb.group({
        username:['',Validators.required],
        password:['',Validators.required]
    });
    this.authService.logout();
    
  }


  login(event){
    this.config.hide(event)
    this.authService.login(this.detailForm.getRawValue() as User).subscribe(data=>{
      this.router.navigate(['/client/activities/dashboard'])
    },error=>{
      this.config.show(event);
    });
  
  }
}
