


import { Observable } from 'rxjs';
import { HttpParams, HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { AppConfig } from 'src/app/auth/app-config';
import { Injectable, InjectionToken, Inject } from '@angular/core';
export const DETAIL_ROUTE_SECTION: InjectionToken<string> = new InjectionToken<string>('DETAIL_ROUTE_SECTION');
export const LIST_ROUTE_SECTION: InjectionToken<string> = new InjectionToken<string>('LIST_ROUTE_SECTION');


@Injectable({
  providedIn: 'root'
})
export class AdminListDataService<T> {
  constructor(public http: HttpClient, public config: AppConfig,
    @Inject(LIST_ROUTE_SECTION) public list_url: string, @Inject(DETAIL_ROUTE_SECTION) public detail_url: string) {
    // console.log('sort by column: ' + sortByColumn);
  }

  getAll(id: string, pageNumber: number = 1, pageSize: number = 10,
     sortByColumn: string = 'name', sortAscDesc: string = 'asc', filter: string = ''): Observable<any> {
    // console.log('SortBy: '+ sortByColumn);
    if (!id) {
      id = localStorage.getItem('company');
    }
    let params: HttpParams = new HttpParams()
      .set('limit', pageSize.toString())
      .set('offset', (pageNumber * pageSize).toString())
      .set('ordering', `${sortAscDesc == 'desc' ? '-' : ''}${sortByColumn}`);

    if (filter && filter.length) {
      params = params.set('search', filter);
    }
    // console.log('inside list data service get all..');
    return this.http.get<any>(this.config.apiUrl + `/${this.list_url}/${id}/`, {
      params: params
    })
      .pipe(
        tap((items: any) => {
          // console.log(items);
        })
      );
  }
  getSingle(id: string): Observable<T> {
    return this.http.get<T>(`${this.config.apiUrl}/${this.detail_url}/${id}/`);
  }

  create(item: T): Observable<any> {
    return this.http.post(`${this.config.apiUrl}/${this.detail_url}/`, item);
    // return this.http.post(`${this.config.apiUrl}/${this.list_url}/`, item);
  }

  update(id: string, item: T): Observable<any> {
    return this.http.put(`${this.config.apiUrl}/${this.detail_url}/${id}/`, item);
  }
  delete(id: string): Observable<T> {
    return this.http.delete<T>(`${this.config.apiUrl}/${this.detail_url}/${id}/`);
  }






}
