export class ListQueryObj {
    pageNumber:number = 1;
    pageSize:number=10;
    sortByColumn: string='name';
    sortAscDesc: string='asc';
    filter: string=''

    constructor(sortByColumn: string='name', sortAscDesc: string='asc'){
        this.sortByColumn = sortByColumn;
        this.sortAscDesc = sortAscDesc
    }
}
