import { Injectable } from '@angular/core';
import { ListDataService } from '../common/services/list-data-service';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../auth/app-config';
import { ONU } from './models/onu';
import { ListEventSourceService } from '../common/services/list-event-source.service';
@Injectable({
  providedIn: 'root'
})
export class OnuDeviceService extends  ListEventSourceService<ONU>{

  constructor(public http: HttpClient, public config: AppConfig) {
    super(http, config, 'devicemonitor/onudevices', 'devicemonitor/onudevice');
  } 

}