import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { FlexLayoutModule } from '@angular/flex-layout';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MaterialModule } from './material.module';
import { NavigationModule } from './navigation/navigation.module';
import { AuthModule } from './auth/auth.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { ReactiveFormsModule } from '@angular/forms';
import { ErrorDialogComponent } from './auth/error-dialog/error-dialog.component';
import { DeleteDialogComponent } from './navigation/delete-dialog/delete-dialog.component';
// import { AdminModule } from './admin/admin.module';

import { OverlayModule } from '@angular/cdk/overlay';

import { ConfirmDialogComponent } from './common/confirm-dialog/confirm-dialog.component';
import { environment } from '../environments/environment';
import { ToastrModule } from 'ngx-toastr';  
import { AsyncPipe } from '@angular/common';


@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
 


  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    DashboardModule,
    NavigationModule,
    AuthModule,
    ToastrModule.forRoot(),
 
    // AngularFireDatabaseModule,
    // AngularFireAuthModule,
    // AngularFireMessagingModule,
    // AngularFireModule.initializeApp(environment.firebase),


    //AdminModule,
    AppRoutingModule
 
  ],
  providers: [
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 }},AsyncPipe],
  bootstrap: [AppComponent],
  entryComponents: [ErrorDialogComponent, DeleteDialogComponent, ConfirmDialogComponent]
})
export class AppModule { }

