import { Injectable } from '@angular/core';
import { ListDataService } from 'src/app/common/services/list-data-service';
import { BiometricCategory, BiometricSubCategory } from '../models/biometric';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from 'src/app/auth/app-config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BiometricSubCategoryService extends ListDataService<BiometricSubCategory> {

  constructor(public http:HttpClient,public config:AppConfig) {
    super(http,config,"netpapbiometric/sub-categories","netpapbiometric/sub-category")
   }
   getSubcategories(id:string):Observable<BiometricSubCategory>{
    return this.http.get<BiometricSubCategory>(`${this.config.apiUrl}/netpapbiometric/category/${id}/subcategories/`)
   }
}
