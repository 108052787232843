import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'chunk'
})
export class ChunkPipe implements PipeTransform {

 transform(calendarDaysArray:any,chunkSize:number):any {
  let calenderDays = [];
  let weekDays = [];
  calendarDaysArray.map((day,index) =>{
    weekDays.push(day);

    if(++index % chunkSize === 0){
      calenderDays.push(weekDays);
      weekDays = [];
    }
  });
  return calenderDays;
   
 }
}
