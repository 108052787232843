import { Component, Inject, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-period-calculator',
  templateUrl: './period-calculator.component.html',
  styleUrls: ['./period-calculator.component.scss']
})
export class PeriodCalculatorComponent implements OnInit {
  current_value:number;
  detailForm:FormGroup;
  imageUrldialog='assets/img/invoice.jpeg';
  constructor(public fb:FormBuilder,private snackBar:MatSnackBar,public dialogRef: MatDialogRef<PeriodCalculatorComponent>, 
    @Inject(MAT_DIALOG_DATA) public p: any) { }

  ngOnInit() {
    this.detailForm = this.fb.group({
      action:['ADD',Validators.required],
      expiration:[1,Validators.required],
      expiration_type: ['HOURS',Validators.required],        
    })
    console.log(this.p)
    this.current_value = Number(this.p)      
    
  }

  save(e){
    let data = this.detailForm.getRawValue();
    if (data['action'] == "ADD"){
      data['value']=this.current_value + this.calculator(data)
    }else{
      data['value']=this.current_value -  this.calculator(data)
    }

    this.dialogRef.close(data['value']);

  }
  calculator(data){
    console.log(data)
    if(data['expiration_type'] === "MINUTES"){
      return data['expiration'] * 60
    }
    else if(data['expiration_type'] === "HOURS"){
      return data['expiration'] * 60 * 60
    }
    else if(data['expiration_type'] === "DAYS"){
      return data['expiration'] * 60 * 60 * 24
    }
    else if(data['expiration_type'] === "MONTHS"){
      return data['expiration'] * 60 * 60 * 24 * 30
    }
    else if(data['expiration_type'] === "YEARS"){
      return data['expiration'] * 60 * 60 * 24 * 30 * 365
    }
    else{
      this.snackBar.open("Invalid Entry")
      return 0;
      
    }

  }


}
