import { Injectable } from '@angular/core';
import { Offer, Plan } from '../models/plan';
import { ListDataService } from 'src/app/common/services/list-data-service';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from 'src/app/auth/app-config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlanService extends ListDataService<Plan> {

  constructor(public http: HttpClient, public config: AppConfig) {
    super(http, config, 'management/plans', 'management/plan');
  }
  getCompanyDefaultPlans(){

    return this.http.get(`${this.config.apiUrl}/network/defaultcompany/plans/`);
  }
  getOfferList():Observable<Offer>{
    return this.http.get<Offer>(`${this.config.apiUrl}/management/planoffers/`);
  }

}
