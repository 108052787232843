import { Component, OnInit, DoCheck } from '@angular/core';
import { Question } from 'src/app/admin/model/question';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { SecurityService } from 'src/app/admin/services/security.service';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppConfig } from '../app-config';
import { BehaviorSubject, Observable } from 'rxjs';
import { SelectorFlags } from '@angular/compiler/src/core';
import { ValidateUsername } from 'src/app/validators/username.validator';
import { Company } from 'src/app/admin/model/company';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-security-question',
  templateUrl: './security-question.component.html',
  styleUrls: ['./security-question.component.scss']
})
export class SecurityQuestionComponent implements OnInit {
  questions: Question[];
  detailForm: FormGroup;
  securityForm: FormGroup;
  requestTrials = 0;
  securityformDisplay = false;
  username: string;
  companies:Company[];
  displaySendOTPBTN = true;
  user: string;
  showUsername=false;
  options: Company[];
  security_code = false;
  filteredOptions: Observable<Company[]>;

  showTimer = false;
  public stopper;
  public state = new BehaviorSubject({
    minutes: 1,
    seconds: 60

  });
  company = new FormControl();


  constructor(private securityService: SecurityService, private router: Router,
    private authUser: AuthService, private appConfig: AppConfig,
    private route: ActivatedRoute,
    private fb: FormBuilder, private snackBar: MatSnackBar) {

     }

  ngOnInit() {
    this.detailForm = this.fb.group({
      username: ['', [Validators.required]],
      name:['']
    });


  }




  recover(e?:Event) {
    this.appConfig.hide(e)
    if (!this.detailForm.invalid) {
      this.username = this.detailForm.get('username').value;
      this.authUser.recoverAccount(this.detailForm.getRawValue()).subscribe(data=>{
        if(data['count']== "1"){
          // this.router.navigate(['/auth/selectcompany',data['username'],'recoveraccount']);
          this.companies=data['companies']
          this.detailForm.disable();

        }
        this.snackBar.open(data['message'] || data['company']  || data['username'])
        this.detailForm.get('username').setValue(this.username);
        this.appConfig.show(e)

      },error=>{
        this.snackBar.open("An error occurred.Please contact support");
          this.detailForm.get('username').setValue(this.username);
          this.appConfig.show(e)
      })


    }
  }
  setCompanyName(name:string){
    this.companies=[];
    this.snackBar.open(`You have selected ${name}`);
    this.detailForm.get("name").setValue(name);
    this.detailForm.enable();
    this.recover();
  }
}
