import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../auth.service';
import { NetpapInvoiceListService } from 'src/app/networks/services/netpap-invoice-list.service';
import { MatAccordion } from '@angular/material/expansion';


@Component({
  selector: 'app-default-page',
  templateUrl: './default-page.component.html',
  styleUrls: ['./default-page.component.scss']
})
export class DefaultPageComponent implements OnInit {
  invoices:[];
  company_name="";
  @ViewChild(MatAccordion,{static: false}) accordion: MatAccordion;
  constructor(private authService:AuthService,private companyInvoices:NetpapInvoiceListService) { }

  ngOnInit() {
    this.company_name=this.authService.getCompanyName();
    let company_id=this.authService.getUserDetails()['company']
    this.companyInvoices.defaulted_invoices(company_id).subscribe(data=>{
      this.invoices=data['results']
    })
  }

}
