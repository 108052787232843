import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';


@Component({
  selector: 'app-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss']
})
export class PromptComponent {

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data:{mobileType:'ios' | 'android',promptEvent?:any},
  private bottomSheetRef: MatBottomSheetRef<PromptComponent>
  ) { }
    public installNetpap(): void{
      console.log(this.data)
      this.data.promptEvent.prompt();
      this.close();
    }
    public close(){
    this.bottomSheetRef.dismiss();
    }

}
