import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExportExcelService {

  constructor() { }

  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';

  public export(jsonData: any[], fileName: string): void {
    // remove key value with uuid
    let finalJson= this.objJson(jsonData)

    console.log(finalJson)

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  }
  private objJson(payload:any[]){
    // Define a type for the accumulator
type Accumulator = Record<string, any>;

const filtered:any[] = payload.reduce(function(accumulator, pyldObj) {
  var filteredp = {};
  const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/i;
  const uuidRegex2 = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  Object.keys(pyldObj).forEach(function(key) {
    var value = pyldObj[key];
    if (!uuidRegex.test(value)) {
      filteredp[key] = value;
    }
    else if (!uuidRegex2.test(value)) {
      filteredp[key] = value;
    }
  });

      accumulator[pyldObj.id] = filteredp;

  return accumulator;
}, {});


let cleanedObj = Object.keys(filtered).reduce(function(accumulator, key) {
  var value = filtered[key];
  if (value) {
    accumulator[key] = value;
  }
  return accumulator;
}, {});
  return cleanedObj;
  }
  public  isValidUUID(str: string): boolean {
    const uuidRegex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    return uuidRegex.test(str);
  }
  
  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: this.fileType});
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }
}
