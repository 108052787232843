import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-bandwidth-tool',
  templateUrl: './bandwidth-tool.component.html',
  styleUrls: ['./bandwidth-tool.component.scss']
})
export class BandwidthToolComponent implements OnInit {
  detailForm:FormGroup;
  imageUrldialog='assets/img/invoice.jpeg';
  constructor(public fb:FormBuilder,private snackBar:MatSnackBar,public dialogRef: MatDialogRef<BandwidthToolComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }


  ngOnInit() {

    
    let t = this.data.split(" ")
   

    console.log(t);
    try{
      let rx_rate = t[0].split("/");
      let rx_burst_rate = t[1].split("/");
      let rx_burst_threshold =  t[2].split("/");
      let rx_burst_time = t[3].split("/");
      let rx_rate_min = t[5].split("/");

      this.detailForm = this.fb.group({
        rx_rate_min:[rx_rate_min[0],Validators.required],
        tx_rate_min:[rx_rate_min[1],Validators.required],
  
        priority:[t[4],Validators.required],
  
        rx_burst_time:[rx_burst_time[0],Validators.required],
        tx_burst_time:[rx_burst_time[1],Validators.required],
  
  
        rx_burst_threshold:[rx_burst_threshold[0],Validators.required],
        tx_burst_threshold:[rx_burst_threshold[1],Validators.required],
  
        
        rx_burst_rate:[rx_burst_rate[0],Validators.required],
        tx_burst_rate:[rx_burst_rate[1],Validators.required],
  
        rx_rate:[rx_rate[0],Validators.required],
        tx_rate:[rx_rate[1],Validators.required],
        
      })
    }
    catch (e) {
      t=t[0].split("/")
      console.log(t,"-------")
      this.detailForm = this.fb.group({
        rx_rate_min:['',Validators.required],
        tx_rate_min:['',Validators.required],
  
        priority:['',Validators.required],
  
        rx_burst_time:['',Validators.required],
        tx_burst_time:['',Validators.required],
  
  
        rx_burst_threshold:['',Validators.required],
        tx_burst_threshold:['',Validators.required],
  
        
        rx_burst_rate:[0,Validators.required],
        tx_burst_rate:[0,Validators.required],
  
        rx_rate:[t[0],Validators.required],
        tx_rate:[t[1],Validators.required],
        
      })  
    }
    
   
    
    


    
  }



closeDialog() {
    let bw = this.detailForm.getRawValue();
    if(bw['rx_rate_min'] && bw['priority'] ){
      if (Number(bw['rx_rate_min'].replace("k","").replace("M","")) > Number(bw['rx_rate'].replace("k","").replace("M","")) || Number(bw['tx_rate_min'].replace("k","").replace("M","")) > Number(bw['tx_rate'].replace("k","").replace("M",""))){

        this.snackBar.open("Min rx/tx cannot be greater than  rx_rate/tx_rate");
        return false;
      }
      this.dialogRef.close(bw['rx_rate']+"/"+bw['tx_rate']+" "+bw['rx_burst_rate']+"/"+bw['tx_burst_rate']+" "+bw['rx_burst_threshold']+"/"+bw['tx_burst_threshold']+" "+bw['tx_burst_time']+"/"+bw['rx_burst_time']+" "+bw['priority']+" "+bw['rx_rate_min']+"/"+bw['tx_rate_min']);
    }else{
      this.dialogRef.close(bw['rx_rate']+"/"+bw['tx_rate']);  
    }
  }

}
