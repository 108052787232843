import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LoginLayoutComponent } from './login-layout/login-layout.component';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SecurityQuestionComponent } from './security-question/security-question.component';
import { AddSecurityQuestionsComponent } from './add-security-questions/add-security-questions.component';
import { DefaultPageComponent } from './default-page/default-page.component';
import { MobileLoginComponent } from './mobile/mobile-login/mobile-login.component';
import { MobileCompanyListComponent } from './mobile/mobile-company-list/mobile-company-list.component';
import { MobileSplashComponent } from './mobile/mobile-splash/mobile-splash.component';
import { OrderingComponent } from './ordering/ordering.component';
import { AgreementComponent } from './mobile/agreement/agreement.component';
import { TermsComponent } from './terms/terms.component';
import { SelectCompanyComponent } from './select-company/select-company.component';

const routes: Routes = [
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: 'auth',
        children: [
          {path: 'login', component: LoginComponent},
          {path:'selectcompany/:username/:origin',component:SelectCompanyComponent},
          {path: 'order', component: OrderingComponent},

          { path: 'recover', component: SecurityQuestionComponent},
          { path: 'securityquestion', component: SecurityQuestionComponent },
          { path: 'resetpassword', component: ResetPasswordComponent },
          {path: 'defaultpage',component: DefaultPageComponent},
          {path: '', redirectTo: 'login', pathMatch: 'full'}

        ]
      },
      {
        path:'mobile',
        children: [
          {path: 'login',component:MobileLoginComponent },
          {path:'resetpassword', component:ResetPasswordComponent},
          {path:'companies',component:MobileCompanyListComponent},
          {path:'splash',component:MobileSplashComponent},
          {path:'agreement',component:TermsComponent},
          {path:'agreement/:company',component:AgreementComponent}
        ]
      }
    ]
}
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
