import { Injectable } from '@angular/core';


import { AppConfig } from 'src/app/auth/app-config';
import { HttpClient } from '@angular/common/http';
import { AdminListDataService } from './admin-list-data.service';
import { Question } from '../model/question';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SecurityService extends AdminListDataService<Question> {
  public d = new Date();
  public state = new BehaviorSubject({
    minutes: 2,
    seconds:60

  });
  public stopper;
  public obs$ = this.state.asObservable();
  constructor(public http: HttpClient, public config: AppConfig) {
    super(http, config, 'admin/securityquestions', 'admin/securityquestion');
  }
  addUserQuestion(question: any) {
    return this.http.post<Question>(`${this.config.apiUrl}/v1/client/securityquestions/`, question);

  }
  getQuestion(question: Question) {
    return this.http.post<Question>(`${this.config.apiUrl}/v1/client/securityquestion/`, question);
  }
  getOTP(user: string): any {
    return this.http.post<any>(`${this.config.apiUrl}/v1/client/generateotp/`, {'user': user});
  }
  startTimer() { // count to zero

    const update = this.state.value;

    this.stopper = setInterval((k, v) => {

      if (update.seconds > 0) {
        update.seconds -= 1;
      }
      if (update.seconds === 0 && update.minutes >= 1) {
        update.seconds = 60;
        update.minutes -= 1;
      }
      if (update.seconds === 0 && update.minutes === 0) {
        update.minutes = 0;
        clearInterval(this.stopper);
      }
      this.state.next(update);

    }, 1000);
  }

  tickerTimer() {
    return this.obs$;
  }

  recoverPassword(sec: any) {
    return this.http.post<any>(`${this.config.apiUrl}/v1/client/account/recover/`, sec);
  }

}
