import { Component, OnInit, Inject } from "@angular/core";

import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  BiometricCategory,
  BiometricDevice,
  BiometricSubCategory,
  BiometricUser,
} from "src/app/netpapbiometric/models/biometric";
import { BiometricCategoryService } from "src/app/netpapbiometric/services/biometric-category.service";
import { BiometricDeviceService } from "src/app/netpapbiometric/services/biometric-device.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { AppConfig } from "src/app/auth/app-config";
import { CommonService } from "src/app/admin/services/common.service";
import { ActivatedRoute, Router } from "@angular/router";
import { BiometricUserService } from "src/app/netpapbiometric/services/biometric-user.service";
import { BaseListComponent } from "../base-list/base-list.component";
import { ListDatasource } from "../services/list-datasource";
import { BiometricSubCategoryService } from "src/app/netpapbiometric/services/biometric-sub-categories.service";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
@Component({
  selector: "app-network-attribute-detail",
  templateUrl: "./netpapbiometric-user-attribute-detail.component.html",
  styleUrls: ["./netpapbiometric-user-attribute-detail.component.scss"],
})
export class NetpapBiometricUserAttributeDetailComponent
  extends BaseListComponent
  implements OnInit {
  router_details: any;
  categories: BiometricCategory;
  subcategories: BiometricSubCategory
  devices: BiometricDevice;

  detailForm: FormGroup;


  imageUrldialog = "assets/img/invoice.jpeg";
  actionType: string; // Define a property to store the action type (bulkuser or import)

  constructor(
    private mainService: BiometricUserService,
    private fb: FormBuilder,
    public config: AppConfig,
    public dialogRef: MatDialogRef<NetpapBiometricUserAttributeDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private categoryService: BiometricCategoryService,
    private subcategoryService: BiometricSubCategoryService,
    private biometricDevice: BiometricDeviceService,
    private commonService: CommonService,
    public router: Router,
    public route: ActivatedRoute,
    private dialog: MatDialog,

    public snackBar: MatSnackBar
  ) {
    super(
      new ListDatasource<BiometricUser>(mainService),
      config,
      snackBar,
      router,
      route,
      "user"
    );
    this.actionType = this.data.type; // Get the action type from the data passed to the dialog

  }
  downloadCSV(payload) {
    // this.config.hide(e);

    const { category, sub_category, device,type } = payload;

    // Prepare the URL with query parameters for filtering
    let url = type=='downloadEventHistroriesUsers'?'/netpapbiometric/eventHistoriesFileDownload': '/netpapbiometric/fileDownload/';

    let queryParams = '';

    if (device) {
      queryParams += `device=${device}`;
    }

    if (category) {
      queryParams += `${queryParams ? '&' : ''}category=${category}`;
    }

    if (sub_category) {
      queryParams += `${queryParams ? '&' : ''}sub_category=${sub_category}`;
    }

    if (queryParams) {
      url += `?${queryParams}`;
    }


    this.commonService.downloadCSV(url)
      .subscribe(data => {
        const blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'data.csv';

        // Trigger the download without appending the link to the DOM
        a.click();

        // Clean up by revoking the object URL
        window.URL.revokeObjectURL(url);

        // Show the loading indicator again
        // this.config.show(e);
      }, error => {
        console.error('Error downloading CSV:', error);

        // Show the loading indicator again in case of error
        // this.config.show(e);
      });
  }

  ngOnInit() {
    console.log(new Date());

    this.detailForm = this.fb.group({
      device: ["", Validators.required],
    });
    if (this.actionType === "bulkuser") {
      console.log("BULK USER")
      this.detailForm.addControl(
        "category",
        new FormControl("", Validators.required)
      ); // Add category control
      this.detailForm.addControl(
        "sub_category",
        new FormControl("", Validators.required)
      ); // Add sub category control

      this.categoryService.getAll(0, 1000).subscribe((data) => {
        this.categories = data["results"];
      });

    }
    if (this.actionType === "downloadUsers") {
      console.log("DOWNLOAD USERS")
      this.detailForm.addControl(
        "category",
        new FormControl("", Validators.required)
      ); // Add category control
      this.detailForm.addControl(
        "sub_category",
        new FormControl("", Validators.required)
      ); // Add sub category control

      this.categoryService.getAll(0, 1000).subscribe((data) => {
        this.categories = data["results"];
      });

      this.subcategoryService.getAll(0, 1000).subscribe((data) => {
        this.subcategories = data["results"];
      });

    }
    if (this.actionType === "downloadEventHistroriesUsers") {
      console.log("DOWNLOAD EVENT HISTORIES")
      this.detailForm.addControl(
        "category",
        new FormControl("", Validators.required)
      ); // Add category control
      this.detailForm.addControl(
        "start_date",
        new FormControl("", Validators.required)
      );//start_date
      this.detailForm.addControl(
        "end_date",
        new FormControl("", Validators.required)
      );//end_date
      this.detailForm.addControl(
        "sub_category",
        new FormControl("", Validators.required)
      ); // Add sub category control

      this.categoryService.getAll(0, 1000).subscribe((data) => {
        this.categories = data["results"];
      });

      this.subcategoryService.getAll(0, 1000).subscribe((data) => {
        this.subcategories = data["results"];
      });

    }
    // Subscribe to form value changes
    this.detailForm.valueChanges.subscribe((value) => {
      console.log("Form value changes:", value);
      console.log("Form validity after changes:", this.detailForm.valid);
    });
    this.biometricDevice.getAll(0, 1000).subscribe((data) => {
      this.devices = data["results"];
    });

  }




  save(e) {
    this.config.hide(e);
    e.preventDefault();
    const device = this.detailForm.get("device").value; // Get the value of device form control

    if (this.actionType === "bulkuser") {
      const category = this.detailForm.get("category").value; // Get the value of category form control
      const sub_category = this.detailForm.get("sub_category").value;
      this.config.hide(e);
      this.dialogRef.close(); // Close the dialog before making the set_users call

      this.dataSource.set_users(device, category, sub_category).subscribe(
        (data) => {
          this.ngOnInit();
        },
        (fail) => {
          this.ngOnInit();
        }
      );
    }
    if (this.actionType === "downloadUsers") {
      const category = this.detailForm.get("category").value; // Get the value of category form control
      const sub_category = this.detailForm.get("sub_category").value;
      this.config.hide(e);
      this.dialogRef.close(); // Close the dialog before making the set_users call

      const payload = {
        device: device,
        category: category,
        sub_category: sub_category,
        type:'downloadUsers'

      }

      this.downloadCSV(payload)

      // this.dataSource.set_users(device, category,sub_category).subscribe(
      //   (data) => {
      //     this.ngOnInit();
      //   },
      //   (fail) => {
      //     this.ngOnInit();
      //   }
      // );
    }
    if (this.actionType === "downloadEventHistroriesUsers") {
      const category = this.detailForm.get("category").value; // Get the value of category form control
      const sub_category = this.detailForm.get("sub_category").value;
      const start_date = this.detailForm.get("start_date").value;
      const end_date = this.detailForm.get("end_date").value;
      this.config.hide(e);
      this.dialogRef.close(); // Close the dialog before making the set_users call

      const payload = {
        device: device,
        category: category,
        sub_category: sub_category,
        start_date:start_date,
        end_date:end_date,
        type:'downloadEventHistroriesUsers'

      }

      this.downloadCSV(payload )

      // this.dataSource.set_users(device, category,sub_category).subscribe(
      //   (data) => {
      //     this.ngOnInit();
      //   },
      //   (fail) => {
      //     this.ngOnInit();
      //   }
      // );
    }
    else if (this.actionType === "import") {
      this.config.hide(e);

      this.dialogRef.close(); // Close the dialog before making the set_users call

      this.dataSource.download_users(device).subscribe(
        (data) => {
          this.ngOnInit();
        },
        (fail) => {
          this.ngOnInit();
        }
      );

    }

    // console.log("Save button clicked");
    // console.log("Form validity when Save clicked:", this.detailForm.valid);
  }
  getSubcategories(val: string) {
    this.subcategoryService.getSubcategories(val).subscribe((data) => {
      this.subcategories = data["results"];
    });
  }
}
