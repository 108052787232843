import { Component,  Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent {
  message:string;
  constructor(
    public dialogRef: MatDialogRef<DeleteDialogComponent>,@Inject(MAT_DIALOG_DATA) private data) { 
      this.message=data['message']
    }

  onDelete() {
    this.dialogRef.close("DELETE");
  }
  cancel() {
    this.dialogRef.close();
  }

}
