import { Injectable } from '@angular/core';
import { HttpClient, HttpParams} from '@angular/common/http';
import { AppConfig } from 'src/app/auth/app-config';
import { UserList, NextOfKin, AccountUpgrade } from '../model/user-list';
import { ListDataService } from 'src/app/common/services/list-data-service';
import { Observable } from 'rxjs';
import { Bank } from '../model/company';
import { Coupon } from 'src/app/crmui/models/message';

@Injectable({
  providedIn: 'root'
})
export class UserService extends ListDataService<UserList> {

  constructor(public http: HttpClient, public config: AppConfig) {
    super(http, config, 'users/all', 'user');
  }
  updateBank(id: string, bank: Bank) {
    return this.http.put(`${this.config.apiUrl}/admin/bank/update/${id}/`, bank);
  }
  // getBank(user: string) {
  //   return this.http.get(`${this.config.apiUrl}/admin/bank/get/${user}/`);
  // }
  createBank(bank: Bank): Observable<Bank> {
    return this.http.post<Bank>(`${this.config.apiUrl}/admin/bank/create/`, bank);
  }
  // getNextOfKindd(user: string) {
  //   return this.http.get(`${this.config.apiUrl}/admin/nextofkin/update/${user}/`);
  // }

  updateNextOfKin(id: string, nextOfKin: NextOfKin) {
    return this.http.put(`${this.config.apiUrl}/admin/nextofkin/${id}/`, nextOfKin);
  }
  createNextOfKin(nextOfKin: NextOfKin): Observable<NextOfKin> {
    return this.http.post<NextOfKin>(`${this.config.apiUrl}/admin/nextofkins/`, nextOfKin);
  }
  checkIfTSCRegistered() {
    return this.http.get(`${this.config.apiUrl}/v1/client/tsc/check/`);
  }
  upgradeAccount(user: string, accountUpgrade: AccountUpgrade): Observable<AccountUpgrade> {
    return this.http.put<AccountUpgrade>(`${this.config.apiUrl}/v1/client/tsc/register/${user}/`, accountUpgrade);
  }

  searchService(k: string) {
    return this.http.get(`${this.config.apiUrl}/v1/admin/usersearch/${k}/`);
  }
  setStatus(id: string, status: string) {
    return this.http.put(`${this.config.apiUrl}/v1/admin/user/setstatus/${id}/`, {'status': status});
  }
  getNetworkAttr(id:string) {
    return this.http.get(`${this.config.apiUrl}/management/user/networkattrs/${id}/`);
  }
  getNetworkAttrByUsername(username: string) {
    return this.http.get(`${this.config.apiUrl}/management/usernamattr/${username}/`);
  }
  setAttr(id: string,data:any){
    return this.http.put(`${this.config.apiUrl}/management/user/setattrs/${id}/`, data);
  }
  getUserVouchers(id:string){
    return this.http.get(`${this.config.apiUrl}/management/user/vouchers/${id}/`);
  }
getUserTopUps(id:string) {
  return this.http.get(`${this.config.apiUrl}/management/user/topups/${id}/`);
}
  getUserUnallocated(id:string) {
    return this.http.get(`${this.config.apiUrl}/management/user/unallocated/${id}/`);
  }
  getUserInvoices(id:string) { 
    return this.http.get(`${this.config.apiUrl}/invoicing/user/invoices/${id}/`);
  }
  getUserPurchases(id: string) {
    return this.http.get(`${this.config.apiUrl}/management/user/purchases/${id}/`);
  }
  changePlan(username: string, data: any) {
    return this.http.put(`${this.config.apiUrl}/management/user/changeplan/${username}/`, data);
  }
  get_router_data(username,typ){
    return this.http.get(`${this.config.apiUrl}/management/routerlist/${username}/${typ}/`);
  }
  get_user_consumption(form){
    return this.http.put(`${this.config.apiUrl}/management/userdata/`,form);
  }
  reset_user_login(data){
    return this.http.put(`${this.config.apiUrl}/admin/forcepasswordreset/`,data);
  }
  changeUser(data,item){
    return this.http.put(this.config.apiUrl+"/admin/changeusername/"+item['id']+"/",data);
  }
  getLatestUsername(account_type:string){
    let params:HttpParams =new HttpParams().set("account_type",account_type)
    return this.http.get(`${this.config.apiUrl}/admin/retrievelatestusername/`,{params:params});
  }
  getIpAddress(val){
    return this.http.get(`${this.config.apiUrl}/management/usergetipaddress/?pool=${val}`);
  }
  getCouponTypes():Observable<Coupon>{
    return this.http.get<Coupon>(`${this.config.apiUrl}/management/coupons/`);
  }
  getStatusOnUsername(username:string,typ:string){
    let params:HttpParams =new HttpParams().set("username",username).set("typ",typ);
    return this.http.get(`${this.config.apiUrl}/management/statusonusername/`,{params:params});
  }
  macBind(username:string,mac:string,typ:string){
    return this.http.put(`${this.config.apiUrl}/management/statusonusername/`,{"username":username,"mac":mac,"typ":typ})
  }

  upgradetoactive(id:string){
    return this.http.put(`${this.config.apiUrl}/management/user/managestatus/${id}/`,{})
  }

}

