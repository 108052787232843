import { DataSource, CollectionViewer } from "@angular/cdk/collections";
import { BehaviorSubject, Observable } from "rxjs";
import { ListDataService } from "./list-data-service";
import { catchError, finalize } from "rxjs/operators";
import { ListEventSourceService } from "./list-event-source.service";
import { EventSourcePolyfill } from "event-source-polyfill";
import { cloneDeep } from 'lodash'; // Import cloneDeep function for deep copying

export class StreamingListDataSource<T> implements DataSource<T> {
  public itemsSubject = new BehaviorSubject<T[]>([]);
  private originalData: any; // Store the original data here
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private itemsCountSubject = new BehaviorSubject<number>(1);
  private itemsCountOnlineSubject = new BehaviorSubject<number>(1);
  private permsSubject = new BehaviorSubject<any>({});
  private totalSubject = new BehaviorSubject<any>({});

  public loading$ = this.loadingSubject.asObservable();
  public itemsCount$ = this.itemsCountSubject.asObservable();
  public itemsOnlineCount$ = this.itemsCountOnlineSubject.asObservable();
  public perms$ = this.permsSubject.asObservable();
  public totals$ = this.totalSubject.asObservable();
  public resultData$ = this.itemsSubject.asObservable();
  public filterPredicate: (data: T, filter: string) => boolean;
  public filter: string;
  public paginator: any;

  constructor(private service: ListEventSourceService<T>) {}

  connect(collectionViewer: CollectionViewer): Observable<T[]> {
    return this.itemsSubject.asObservable();
  }


  search(searchTerm: string) {
    // Convert the searchTerm to lowercase for case-insensitive matching
    const searchTermLowerCase = searchTerm.toLowerCase().trim();
  
    // Apply the search filter to the dataSource
    this.filterPredicate = (data: T, filter: string) => {
      // Check if any property of the data matches the search term
      return Object.keys(data).some(key => {
        // Convert the property value to lowercase for case-insensitive matching
        const value = String(data[key]).toLowerCase();
        // Return true if the property value contains the search term
        return value.includes(searchTermLowerCase);
      });
    };
  
   // Apply the filter to the deep copy of the original data
  let filteredData: T[];

  // Check if any of the filter values are empty, if so, return all data
  if (searchTerm === undefined ) {
    filteredData = this.originalData;
  } else {
    filteredData = this.originalData.filter(item => this.filterPredicate(item, searchTerm));
  }

 

  // Update itemsSubject with the filtered data
  this.itemsSubject.next(filteredData);
  }
  


  applyFilter(
    statusFilter: boolean,
    macAddressFilter: string,
    oltNameFilter: string,
    portFilter: number,
    filter: string // Add the 'filter' parameter
  ) {
   
    console.log("oltNameFilter", oltNameFilter)
  
    // Update the filterPredicate function to use the provided filter parameters
    this.filterPredicate = (data: T, filter: string) => { // Add 'filter' as a parameter
      // console.log("data information", data)
    
      
      return (
        (statusFilter === undefined || data['status'] === statusFilter) &&
        (data['mac_address']?.toLowerCase().trim().includes(macAddressFilter?.toLowerCase().trim()) ||  macAddressFilter?.length === 0 ) &&

        (oltNameFilter === undefined||data['olt_name']?.toLowerCase().trim().includes(oltNameFilter?.toLowerCase().trim())|| oltNameFilter?.length===0) &&
        (portFilter === undefined ||portFilter === null|| data['port'] === portFilter) // Compare data['port'] with portFilter
      );
    };
  
    // Apply the filter to the deep copy of the original data
    let filteredData: T[];
  
    // Check if any of the filter values are empty, if so, return all data
    if (statusFilter === undefined && macAddressFilter?.length === 0 && oltNameFilter?.length==0 && (portFilter === null || portFilter ==undefined) ) {


      console.log("here is the original data")

      filteredData = this.originalData;
      console.log("here is the original data",this.originalData)
    } else {
      console.log("here is the filter data");
      filteredData = this.originalData.filter(item => this.filterPredicate(item, filter));
    }
  
   
  
    // Update itemsSubject with the filtered data
    this.itemsSubject.next(filteredData);
  }



  disconnect(collectionViewer: CollectionViewer): void {
    this.loadingSubject.complete();
    this.itemsSubject.complete();
  }

  delete(id: string): Observable<T> {
    return this.service.delete(id);
  }

  // Fetch data using EventSource
  fetchDataWithEventSource(url: string): void {
    this.loadingSubject.next(true);

    const eventSource = new EventSource(url);

    eventSource.onmessage = (event) => {
      const eventData = JSON.parse(event.data);
      this.itemsSubject.next(eventData.results);
      this.originalData = cloneDeep(this.itemsSubject.value);
      this.itemsCountSubject.next(eventData.count);
      this.itemsCountOnlineSubject.next(eventData.online_count);
      this.permsSubject.next(eventData.perms);
      this.totalSubject.next(eventData.totals);
    };

    eventSource.onerror = (error) => {
      console.error('EventSource error:', error);
      this.loadingSubject.next(false);
      eventSource.close();
    };
  }


 

  // Fetch all data
  fetchAll(
    pageNumber: number = 1,
    pageSize: number = 50,
    sortByColumn: string = "name",
    sortAscDesc: string = "asc",
    filter: string = "",
    params: any = {},
    id?: string,
  ): void {
    this.loadingSubject.next(true);
    console.log("this is called loading ", this.loading$);

    if (id) {
      this.service.getAllById(
        id,
        pageNumber,
        pageSize,
        sortByColumn,
        sortAscDesc,
        filter,
      ).pipe(
        catchError(() => []),
        finalize(() => this.loadingSubject.next(false))
      ).subscribe((data) => {
        this.itemsSubject.next(data.results);
        this.originalData = cloneDeep(data.results); // Corrected assignment
        this.itemsCountSubject.next(data.count);
        this.permsSubject.next(data.perms);
        this.totalSubject.next(data.totals);
      });
    } else {
      this.service.getAll(
        pageNumber,
        pageSize,
        sortByColumn,
        sortAscDesc,
        filter,
        params
      ).pipe(
        catchError(() => []),
        finalize(() => this.loadingSubject.next(false))
      ).subscribe((data) => {
        console.log("this is data");

        this.itemsSubject.next(data.results);
        this.itemsCountSubject.next(data.count);
        this.itemsCountOnlineSubject.next(data?.online_count);
        this.originalData = cloneDeep(data.results); // Corrected assignment
        this.permsSubject.next(data.perms);
        this.totalSubject.next(data.totals);
      });
    }
  }


fetchSingle(id: string): Observable<any> {
  this.loadingSubject.next(true);

  return this.service.getSingle(id).pipe(
    catchError(() => []),
    finalize(() => this.loadingSubject.next(false))
  );
}




}
